import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {pdfjs, Document, Page} from 'react-pdf';
import Pagination from 'react-js-pagination';
import DragScroll from 'react-dragscroll';
import Fullscreen from 'react-full-screen';
import {Collapse} from 'reactstrap';
import _ from 'lodash';
import classNames from 'classnames';
import Spinner from '../Spinner/Spinner';
import ProtectedFileDownload from '../ProtectedFileDownload/ProtectedFileDownload';
import {getCookie, setCookie} from '../../../utils/storageUtils';
import InputEmail from '../InputEmail/InputEmail';
import setAuthToken from '../../../utils/setAuthToken';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Display a PDF document
 *
 * Usage: <PDFViewer url='server path' />
 *
 * For example, to display a document attached to a CME:
 *
 *    <PDFViewer url='/v1/cmes/XXXXXXXX/documents/YYYYYYYY
 *
 *    where XXXXXXXX is the CME object id, and YYYYYYYY is the document object id
 *
 * As an alternative, a LOCAL file (on the client) can be displayed by specifying:
 *
 *    <PDFViewer file={fileObject} />
 *
 *    where fileObject is obtained via an <input type='file'> tag
 */
export default class PDFViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docId: null,
      file: null,
      pdf: null,
      numPages: null,
      pageNumber: 1,
      scale: 1,
      rotation: [],
      pageWidth: null,
      pageHeight: null,
      fullscreen: false,
      thumbnailsEnabled: true,
      showThumbnails: false,
      thumbnailsHeight: 0,
      title: props.title,
      noDocumentMessage: props.noDocumentMessage,
      allowEdit: props.allowEdit,
      showEmailFields: false,
      isEmailAddressValid: true
    };
  }

  componentDidMount() {
    this.setState({
      docId: this.props.docId,
      file: this.props.url
          ? {
            url: this.props.url,
            httpHeaders: {'Authorization': getCookie('jwtToken')}
          }
          : this.props.file,
      thumbnailsEnabled: typeof this.props.thumbnailsEnabled === 'boolean' ? this.props.thumbnailsEnabled : true,
      emailAddress: this.props.emailAddress
    });
  }

  componentDidUpdate(prevProps) {
    const stateUpdates = {};
    if ((!!this.props.url && (prevProps.url !== this.props.url)) || (prevProps.file !== this.props.file)) {
      stateUpdates.file =
          this.props.url
              ? {
                url: this.props.url,
                httpHeaders: {'Authorization': getCookie('jwtToken')}
              }
              : this.props.file;
      stateUpdates.numPages = null;
      stateUpdates.pageNumber = 1;
      stateUpdates.scale = 1;
      stateUpdates.rotation = [];
      stateUpdates.showThumbnails = false;
      stateUpdates.fullScreen = false;
    }
    if (prevProps.docId !== this.props.docId) {
      stateUpdates.docId = this.props.docId;
    }
    if (prevProps.title !== this.props.title) {
      stateUpdates.title = this.props.title;
    }
    if (prevProps.noDocumentMessage !== this.props.noDocumentMessage) {
      stateUpdates.noDocumentMessage = this.props.noDocumentMessage;
    }
    if (prevProps.allowEdit !== this.props.allowEdit) {
      stateUpdates.allowEdit = this.props.allowEdit;
    }
    if (prevProps.emailAddress !== this.props.emailAddress) {
      stateUpdates.emailAddress = this.props.emailAddress;
    }
    if (Object.keys(stateUpdates).length > 0) {
      this.setState(stateUpdates);
    }
  }

  handleDocumentLoaded = (pdf) => {
    const rotation = [];
    for (let i = 1; i <= pdf.numPages; i++) {
      rotation[i] = null;
    }
    this.setState({pdf, rotation, numPages: pdf.numPages});
    this.props.onPageCountChange(pdf.numPages);
  };

  // Handler invoked if there's an error loading the document
  handleLoadError = err => {
      // 401 error could mean a bearer token expiration, so refresh the token
      if (err.status === 401) {
        // See if we have a refresh token (and we're not already refreshing!)
        const refreshToken = getCookie('refreshToken');
        if (!refreshToken || !!this.state.isAlreadyRefreshingToken) {
          return;
        }
        // Set a flag in the state to avoid an infinite loop!
        this.setState({isAlreadyRefreshingToken: true}, () => {
          // Use the refresh token to get a new bearer token
          axios.get('/v1/auth/token', {headers: {Authorization: refreshToken}})
          .then(({data}) => {
            // Set the new tokens in the session
            setCookie('jwtToken', data.token);
            setCookie('refreshToken', data.refreshToken);
            setAuthToken(data.token);
            // Now alter the original file spec and stuff in the new bearer token
            const file = _.cloneDeep(this.state.file);
            if (!!file.httpHeaders) {
              file.httpHeaders = {'Authorization': data.token};
            }
            // Clear the "already refreshing" flag and set the modified file spec in the state; this will trigger a re-render
            this.setState({file, isAlreadyRefreshingToken: false});
          })
          .catch(err => {
            this.setState({isAlreadyRefreshingToken: false});
            console.error('Error refreshing bearer token!', err);
          });
        });
      } else {
        if (this.props.onLoadError) {
          this.props.onLoadError(err);
        } else {
          console.error('Error loading document', err);
        }
      }
  };

  handlePageLoaded = (page) => {
    const rotation = this.state.rotation;
    if (rotation[page.pageNumber] === null) {
      rotation[page.pageNumber] = page.rotate;
    }
    this.setState({rotation});
    if (this.props.onPageLoaded) {
      this.props.onPageLoaded(page);
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({pageNumber, scale: 1});
  };

  handleToggleFullscreen = () => {
    this.setState({fullscreen: !this.state.fullscreen});
  };

  handleZoomIn = () => {
    this.setState({scale: this.state.scale * 1.25});
  };

  handleZoomOut = () => {
    this.setState({scale: this.state.scale / 1.25});
  };

  handleRotate = () => {
    const rotation = this.state.rotation;
    rotation[this.state.pageNumber] = (rotation[this.state.pageNumber] + 270) % 360;
    this.setState({rotation});
    this.props.rotatePage(this.state.pageNumber);
  };

  handleToggleThumbnails = () => {
    this.setState({showThumbnails: !this.state.showThumbnails});
  };

  handleThumbnailsHeightChange = () => {
    this.setState({thumbnailsHeight: this.thumbnailsDiv.offsetHeight});
  };

  handleEmailButtonClick = () => {
    this.setState({showEmailFields: !this.state.showEmailFields});
  };

  handleEmailChange = (e) => {
    this.setState({emailAddress: e.target.value});
  };

  handleEmailAddressValidationCheck = isEmailAddressValid => {
    this.setState({isEmailAddressValid});
  };

  handlePassword = (callback, reason) => {
    const callbackProxy = password => {
      // Cancel button handler
      if (password === null) {
        this.setState({
          file: null,
          noDocumentMessage: <span className={'bg-warning p-3'}>(Document is password-protected)</span>
        });
        this.props.onPageCountChange(0);
      } else {
        callback(password);
      }
    };

    const promptText = reason === pdfjs.PasswordResponses.INCORRECT_PASSWORD
        ? 'Incorrect password. Please try again.'
        : 'Enter the password to view this document.';
    const password = prompt(promptText);
    callbackProxy(password);
  };

  /**
   * Use this div wrapper as the Tag in the Collapse component, so that we can get the ref to the DOM node
   * and thus determine the height of the thumbnails container
   */
  DivWrapper = (props) => {
    const {...attributes} = props;
    return (
        <div {...attributes} ref={div => {
          this.thumbnailsDiv = div;
        }}/>
    );
  };

  render() {
    const {allowEdit, title, pageNumber, pdf, numPages, scale, rotation, pageWidth, pageHeight, fullscreen, showThumbnails, thumbnailsHeight, emailAddress, showEmailFields, isEmailAddressValid} = this.state;
    const {pagesComplete} = this.props;
    const thumbnails = [];
    _.times(numPages, page => {
      thumbnails.push(
          <Page
              pdf={pdf}
              pageNumber={page + 1}
              width={75}
              className={classNames({
                'float-left m-2 border cursor-pointer': true,
                'border-dark current': pageNumber === page + 1
              })}
              onClick={() => this.handlePageChange(page + 1)}
              rotate={rotation[page + 1]}
              key={`thumbnail_${page + 1}`}
          >
            <div className={'d-flex justify-content-between'}>
            <span className={'small'}>
              <i className={classNames({'fas fa-check fa-fw text-success': (pagesComplete && pagesComplete.includes(page + 1))})}/>
            </span>
              <span className={'small'}>Page {page + 1}</span>
            </div>
          </Page>
      );
    });
    return (
        <div id={'pdf-viewer-component'} className={this.props.className}>
          <Fullscreen enabled={fullscreen} onChange={fullscreen => this.setState({fullscreen})}>
            <div className={'card'}>
              {title &&
              <div className={'card-header'} ref={div => {
                this.cardHeaderDiv = div;
              }}>
                {this.props.url ? title : this.state.file ? this.state.file.name : ''}
              </div>
              }
              <div className={'card-body'}>
                <div className={'card-title clearfix'} ref={div => {
                  this.cardTitleDiv = div;
                }}>
                  {numPages > 1 &&
                  <Pagination
                      innerClass={'pagination float-left mb-2'}
                      activePage={pageNumber}
                      itemsCountPerPage={1}
                      totalItemsCount={numPages}
                      pageRangeDisplayed={fullscreen ? 20 : 5}
                      onChange={this.handlePageChange}
                      itemClass={'page-item'}
                      linkClass={'page-link'}
                      firstPageText={<i className={'fas fa-angle-double-left'}/>}
                      prevPageText={<i className={'fas fa-angle-left'}/>}
                      nextPageText={<i className={'fas fa-angle-right'}/>}
                      lastPageText={<i className={'fas fa-angle-double-right'}/>}
                  />
                  }
                  <div className={'float-right'}>
                    {this.props.onEmail &&
                    <button className={'btn btn-light'} type={'button'} onClick={this.handleEmailButtonClick}>
                      <i className={'far fa-envelope'}/>
                    </button>
                    }
                    {this.props.url &&
                    <ProtectedFileDownload path={this.props.url} fileName={`${this.state.docId}.pdf`}/>
                    }
                    <button
                        className={classNames({'btn btn-light': true, 'active': fullscreen})}
                        type={'button'}
                        onClick={this.handleToggleFullscreen}
                    >
                      <i className={'fas fa-expand-arrows-alt'}/>
                    </button>
                    <button className={'btn btn-light'} type={'button'} onClick={this.handleZoomIn}>
                      <i className={'fas fa-plus'}/>
                    </button>
                    <button className={'btn btn-light'} type={'button'} onClick={this.handleZoomOut}>
                      <i className={'fas fa-minus'}/>
                    </button>
                    {allowEdit &&
                    <button className={'btn btn-light'} type={'button'} onClick={this.handleRotate}>
                      <i className={'fas fa-undo-alt'}/>
                    </button>
                    }
                    {this.state.thumbnailsEnabled && numPages > 1 &&
                    <button className={'btn btn-light'} type={'button'} onClick={this.handleToggleThumbnails}>
                      <i className={'fas fa-th'}/>
                    </button>
                    }
                  </div>
                </div>
                <Collapse
                    className={'clearfix border rounded mb-2'}
                    isOpen={showThumbnails}
                    tag={this.DivWrapper}
                    onEntered={this.handleThumbnailsHeightChange}
                    onExited={this.handleThumbnailsHeightChange}
                >
                  {thumbnails}
                </Collapse>
                <Collapse className={'clearfix border rounded mb-2'} isOpen={showEmailFields} tag={this.DivWrapper}>
                  <div className={'d-flex align-items-center p-2'}>
                    <InputEmail
                        type={'text'}
                        className={'form-control form-control-lg'}
                        name={'emailAddress'}
                        onChange={this.handleEmailChange}
                        placeholder={'Email Address'}
                        value={emailAddress || ''}
                        onValidationCheck={this.handleEmailAddressValidationCheck}
                    />
                    <button
                        className={'btn btn-primary btn-sm ml-1'} type={'button'}
                        disabled={!isEmailAddressValid}
                        onClick={e => this.props.onEmail(emailAddress.trim())}
                    >
                      Send<i className={'fas fa-envelope ml-1'}/>
                    </button>
                  </div>
                </Collapse>
                <Document
                    inputRef={ref => {
                      if (!!ref && (!pageWidth || ref.clientWidth !== pageWidth)) {
                        this.setState({
                          pageWidth: ref.clientWidth,
                          pageHeight: ref.clientWidth * 11 / 8.5
                        });
                      }
                    }}
                    file={this.state.file}
                    onLoadSuccess={this.handleDocumentLoaded}
                    onLoadError={this.handleLoadError}
                    loading={<Spinner/>}
                    noData={this.state.noDocumentMessage}
                    error={this.state.noDocumentMessage}
                    onPassword={this.handlePassword}
                >
                  {scale === 1 &&
                  <Page
                      pageNumber={pageNumber}
                      width={pageWidth}
                      rotate={rotation[pageNumber]}
                      onLoadSuccess={this.handlePageLoaded}
                  />
                  }
                  {scale < 1 &&
                  <Page
                      pageNumber={pageNumber}
                      scale={scale}
                      rotate={rotation[pageNumber]}
                  />
                  }
                  {scale > 1 &&
                  <DragScroll
                      className={'drag-scroll-container'}
                      width={fullscreen ? window.screen.availWidth - 30 : pageWidth}
                      height={
                        fullscreen
                            ? (window.screen.availHeight - (
                                    this.cardHeaderDiv ? this.cardHeaderDiv.offsetHeight : 0
                                    + this.cardTitleDiv ? this.cardTitleDiv.offsetHeight : 0
                                        + thumbnailsHeight
                                )
                            )
                            : pageHeight
                      }
                  >
                    <Page
                        pageNumber={pageNumber}
                        scale={scale}
                        rotate={rotation[pageNumber]}
                    />
                  </DragScroll>
                  }
                </Document>
              </div>
            </div>
          </Fullscreen>
        </div>
    );
  }
}

PDFViewer.propTypes = {
  url: PropTypes.string,
  file: PropTypes.object,
  onPageCountChange: PropTypes.func,
  rotatePage: PropTypes.func,
  title: PropTypes.node,
  noDocumentMessage: PropTypes.string,
  allowEdit: PropTypes.bool
};