// Step 3
const selectionFrequenciesList = [
  {value: 2, label: 'Semi-annually (twice a year)'},
  {value: 4, label: 'Quarterly (4x per year)'},
  {value: 6, label: 'Bi-monthly (6x per year)'},
  {value: 12, label: 'Monthly (12x per year)'}
]

export default {
  selectionFrequenciesList
}