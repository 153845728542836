import React, {Fragment} from 'react';
import {UncontrolledTooltip} from 'reactstrap';

const drugAlcoholQuestionModalComponent = props => {

  return (
      <Fragment>
        <div className={'form-group'}>
          <div className={'row pb-2'}>
            <div className={'col-sm-10 d-flex align-items-center'}>
              1. Did the employee have alcohol tests with a result of 0.04 or higher?
            </div>
            <div className={props.checklist.q1 ? 'col-sm-2 justify-content-center d-flex' : 'col-sm-2'}>
              <div>
                <button
                    className={props.checklist.q1 ? 'btn btn-outline-danger btn-sm px-4' : 'btn btn-primary btn-sm'}
                    id="q1"
                    name="q1"
                    onClick={props.onChecklistChange}>{props.checklist.q1 ? 'YES' : 'Click for Yes'}
                  {props.checklist.q1 && <i className="fas fa-check pl-1"></i>}
                </button>
              </div>
            </div>
          </div>
          <div className={'row pb-2'}>
            <div className={'col-sm-10 d-flex align-items-center'}>
              2. Did the Employee complete a DOT negative return-to-duty test result as prescribed by a Substance
              Abuse Professional (SAP) (49 CFR Part 40 or 382)? Did the Employee complete a DOT negative return-to-duty
              test result as prescribed by a Substance Abuse Professional (SAP) (49 CFR Part 40 or 382)?
            </div>
            <div className={props.checklist.q2 ? 'col-sm-2 justify-content-center d-flex' : 'col-sm-2'}>
              <div>
                <button
                    className={props.checklist.q2 ? 'btn btn-outline-danger btn-sm px-4' : 'btn btn-primary btn-sm'}
                    id="q2"
                    name="q2"
                    onClick={props.onChecklistChange}>{props.checklist.q2 ? 'YES' : 'Click for Yes'}
                  {props.checklist.q2 && <i className="fas fa-check pl-1"></i>}
                </button>
              </div>
            </div>
          </div>
          <div className="small pl-3 font-weight-bold">- Note: this is not return to duty from extended leave or other
            reasons not prescribed by a SAP.
          </div>
          <div className={'row pb-2'}>
            <div className={'col-sm-10 d-flex align-items-center'}>
              3. Did the Employee refuse to take an alcohol test pursuant to 49 CFR 40.261?
            </div>
            <div className={props.checklist.q3 ? 'col-sm-2 justify-content-center d-flex' : 'col-sm-2'}>
              <div>
                <button
                    className={props.checklist.q3 ? 'btn btn-outline-danger btn-sm px-4' : 'btn btn-primary btn-sm'}
                    id="q3"
                    name="q3"
                    onClick={props.onChecklistChange}>{props.checklist.q3 ? 'YES' : 'Click for Yes'}
                  {props.checklist.q3 && <i className="fas fa-check pl-1"></i>}
                </button>
              </div>
            </div>
          </div>
          <div className={'row pb-2'}>
            <div className={'col-sm-10 d-flex align-items-center'}>
              4. Did the employee refuse a drug test, with the situation specifically listed below: &nbsp; <span
                className={props.q4ReasonsRed ? 'text-danger' : ''}>(Must check one
              that applies)</span>
            </div>
            <div className={props.checklist.q4 ? 'col-sm-2 justify-content-center d-flex' : 'col-sm-2'}>
              <div>
                <button
                    className={props.checklist.q4 ? 'btn btn-outline-danger btn-sm px-4' : 'btn btn-primary btn-sm'}
                    id="q4"
                    name="q4"
                    onClick={props.onChecklistChange}>{props.checklist.q4 ? 'YES' : 'Click for Yes'}
                  {props.checklist.q4 && <i className="fas fa-check pl-1"></i>}
                </button>
              </div>
            </div>
          </div>
          {props.showQ4Details && <div className="small pl-3 ">
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q41"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q41}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q41'} className={'m-0'}>
                  (1) Fail to appear for any test (except a
                  pre-employment
                  test) within a reasonable time, as determined by the employer, consistent with applicable DOT agency
                  regulations, after being directed to do so by the employer. This includes the failure of an employee
                  (including an owner-operator) to appear for a test when called by a C/TPA (see §40.61(a));
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q42"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q42}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q42'} className={'m-0'}>
                  (2) Fail to remain at the testing site until
                  the testing process
                  is complete; Provided, That an employee who leaves the testing site before the testing process
                  commences
                  (see §40.63(c)) for a pre-employment test is not deemed to have refused to test;
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q43"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q43}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q43'} className={'m-0'}>
                  (3) Fail to provide a urine specimen for
                  any drug test required
                  by this part or DOT agency regulations; Provided, That an employee who does not provide a urine
                  specimen
                  because he or she has left the testing site before the testing process commences (see §40.63(c)) for a
                  pre-employment test is not deemed to have refused to test;
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q44"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q44}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q44'} className={'m-0'}>
                  (4) In the case of a directly observed or
                  monitored collection
                  in a drug test, fail to permit the observation or monitoring of your provision of a specimen (see
                  §§40.67(l) and 40.69(g));
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q45"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q45}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q45'} className={'m-0'}>
                  (5) Fail or decline to take an additional
                  drug test the employer
                  or collector has directed you to take (see, for instance, §40.197(b));
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q46"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q46}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q46'} className={'m-0'}>
                  (6) Fail to cooperate with any part of the
                  testing process
                  (e.g., refuse to empty pockets when directed by the collector, behave in a confrontational way that
                  disrupts the collection process, fail to wash hands after being directed to do so by the collector).
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q47"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q47}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q47'} className={'m-0'}>
                  (7) For an observed collection, fail to
                  follow the observer’s
                  instructions to raise your clothing above the waist, lower clothing and underpants, and to turn around
                  to permit the observer to determine if you have any type of prosthetic or other device that could be
                  used to interfere with the collection process.
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q48"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q48}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q48'} className={'m-0'}>
                  (8) Possess or wear a prosthetic or other
                  device that could be used
                  to interfere with the collection process.
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q49"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q49}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q49'} className={'m-0'}>
                  (9) Admit to the collector that you
                  adulterated or substituted the
                  specimen.
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="radio"
                         id="q410"
                         name="q4_radios"
                         checked={props.checklist && props.checklist.q4 && props.checklist.q410}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 custom-sm-checkbox py-1">
                <label htmlFor={'q410'} className={'m-0'}>
                  (10) Collector indicates Refusal of test.
                  Collector must indicate
                  “refusal” in the Remarks section of the Chain of Custody form.
                </label>
              </div>
            </div>
          </div>}
          <div className={'row pb-2'}>
            <div className={'col-sm-10 d-flex align-items-center'}>
              5. Has the driver successfully completed all follow-up tests as prescribed in the SAP report in
              accordance with §§ 40.307, 40.309, and 40.311 of this title. (if so final SAP report must be uploaded)
            </div>
            <div className={props.checklist.q5 ? 'col-sm-2 justify-content-center d-flex' : 'col-sm-2'}>
              <div>
                <button
                    className={props.checklist.q5 ? 'btn btn-outline-danger btn-sm px-4' : 'btn btn-primary btn-sm'}
                    id="q5"
                    name="q5"
                    onClick={props.onChecklistChange}>{props.checklist.q5 ? 'YES' : 'Click for Yes'}
                  {props.checklist.q5 && <i className="fas fa-check pl-1"></i>}
                </button>
              </div>
            </div>
          </div>
          <div className={'row pb-2'}>
            <div className={'col-sm-10 d-flex align-items-center'}><span>
              6. Has the Employee had the any of the following violations of which the employer obtains&nbsp;
              <span id={"actualKnowledge2"} style={{textDecoration: 'underline'}}>actual knowledge</span>, as defined at § 382.107, of: &nbsp;
              <span
                  className={props.q6ReasonsRed ? 'text-danger' : ''}> (Must check all that
              apply)</span></span>
            </div>
            <div className={props.checklist.q6 ? 'col-sm-2 justify-content-center d-flex' : 'col-sm-2'}>
              <div>
                <button
                    className={props.checklist.q6 ? 'btn btn-outline-danger btn-sm px-4' : 'btn btn-primary btn-sm'}
                    id="q6"
                    name="q6"
                    onClick={props.onChecklistChange}>{props.checklist.q6 ? 'YES' : 'Click for Yes'}
                  {props.checklist.q6 && <i className="fas fa-check pl-1"></i>}
                </button>
              </div>
            </div>
          </div>
          {props.showQ6Details && <div className="small pl-3">
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="checkbox"
                         id="q61"
                         name="q61"
                         checked={props.checklist && props.checklist.q6 && props.checklist.q61}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 form-control-sm custom-sm-checkbox">
                <label htmlFor={'q61'} className={'m-0'}>
                  (i) On-duty alcohol use pursuant to <span
                    id={"reg205"}>§ 382.205;</span>
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="checkbox"
                         id="q62"
                         name="q62"
                         checked={props.checklist && props.checklist.q6 && props.checklist.q62}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 form-control-sm custom-sm-checkbox">
                <label htmlFor={'q62'} className={'m-0'}>
                  (ii) Pre-duty alcohol use pursuant to <span
                    id={"reg207"}>§ 382.207;</span>
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="checkbox"
                         id="q63"
                         name="q63"
                         checked={props.checklist && props.checklist.q6 && props.checklist.q63}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 form-control-sm custom-sm-checkbox">
                <label htmlFor={'q63'} className={'m-0'}>
                  (iii) Alcohol use following an accident
                  pursuant to <span
                    id={"reg209"}>§ 382.209;</span> and
                </label>
              </div>
            </div>
            <div className="row pb-2 input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input type="checkbox"
                         id="q64"
                         name="q64"
                         checked={props.checklist && props.checklist.q6 && props.checklist.q64}
                         onChange={(e) => props.onChecklistChange(e)}
                  />
                </div>
              </div>
              <div className="col-sm-11 form-control-sm custom-sm-checkbox">
                <label htmlFor={'q64'} className={'m-0'}>
                  (iv) Controlled substance use pursuant
                  to <span
                    id={"reg213"}>§ 382.213.</span>
                </label>
              </div>
            </div>
          </div>}
          <div className={'row pb-1 pl-3 font-weight-bold'} style={{textDecoration: 'underline'}}>
            DOT Notices within the regulations:
          </div>
          <div className={'row pb-2 pl-4 font-weight-bold'}>
            49CFR Part 382.705(e): Reporting truthfully and accurately. Every person or entity with access must report
            truthfully and accurately to the Clearinghouse and is expressly prohibited from reporting information he or
            she knows or should know is false or inaccurate.
          </div>
          <div className={'row pl-4 font-weight-bold'}>
            49CFR Part 382.705(c) C/TPAs. Any employer may designate a C/TPA to perform the employer requirements in
            paragraph (b) of this section. Regardless of whether it uses a C/TPA to perform its requirements, the
            employer retains ultimate responsibility for compliance with this section. Exception: An employer does not
            retain responsibility where the C/TPA is designated to comply with employer requirements as described in
            paragraph (b)(6) [owner-operator] of this section.
          </div>
        </div>
        <UncontrolledTooltip placement="right"
                             target={'actualKnowledge2'}
                             container='#root'
                             innerClassName={'tooltip-inner-wide'}>
          Actual knowledge for the purpose of subpart B of this part, means actual knowledge by an employer that a
          driver has used alcohol or controlled substances based on the employer's direct observation of the employee,
          information provided by the driver's previous employer(s), a traffic citation for driving a CMV while under
          the influence of alcohol or controlled substances or an employee's admission of alcohol or controlled
          substance use, except as provided in §382.121. Direct observation as used in this definition means observation
          of alcohol or controlled substances use and does not include observation of employee behavior or physical
          characteristics sufficient to warrant reasonable suspicion testing under §382.307. As used in this section,
          “traffic citation” means a ticket, complaint, or other document charging driving a CMV while under the
          influence of alcohol or controlled substances.
        </UncontrolledTooltip>
        {props.showQ6Details && <Fragment><UncontrolledTooltip placement="top"
                                                               target={'reg205'}
                                                               container='#root'>
          No driver shall use alcohol while performing safety-sensitive functions. No employer having actual knowledge
          that a driver is using alcohol while performing safety-sensitive functions shall permit the driver to perform
          or continue to perform safety-sensitive functions
        </UncontrolledTooltip>
          <UncontrolledTooltip placement="top"
                               target={'reg207'}
                               container='#root'>
            No driver shall perform safety-sensitive functions within four hours after using alcohol. No employer having
            actual knowledge that a driver has used alcohol within four hours shall permit a driver to perform or
            continue
            to perform safety-sensitive functions.
          </UncontrolledTooltip>
          <UncontrolledTooltip placement="top"
                               target={'reg209'}
                               container='#root'>
            No driver required to take a post-accident alcohol test under §382.303 shall use alcohol for eight hours
            following the accident, or until he/she undergoes a post-accident alcohol test, whichever occurs first.
          </UncontrolledTooltip>
          <UncontrolledTooltip placement="right"
                               target={'reg213'}
                               container='#root'
                               innerClassName={'tooltip-inner-wide'}
          >
            (a) No driver shall report for duty or remain on duty requiring the performance of safety sensitive
            functions
            when the driver uses any drug or substance identified in 21 CFR 1308.11 Schedule I.
            (b) No driver shall report for duty or remain on duty requiring the performance of safety-sensitive
            functions
            when the driver uses any non-Schedule I drug or substance that is identified in the other Schedules in 21
            CFR
            part 1308 except when the use is pursuant to the instructions of a licensed medical practitioner, as defined
            in §382.107, who is familiar with the driver's medical history and has advised the driver that the substance
            will not adversely affect the driver's ability to safely operate a commercial motor vehicle.
            (c) No employer having actual knowledge that a driver has used a controlled substance shall permit the
            driver
            to perform or continue to perform a safety-sensitive function.
            (d) An employer may require a driver to inform the employer of any therapeutic drug use.
          </UncontrolledTooltip></Fragment>}
      </Fragment>
  );
};

export default drugAlcoholQuestionModalComponent;