import types from './types';

const setSessionStatus = status => ({
  type: types.SET_SESSION_STATUS,
  payload: status
});

const setSocket = socket => ({
  type: types.SET_SOCKET,
  payload: socket
});

export default {
  setSessionStatus,
  setSocket
}