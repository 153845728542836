import axios from 'axios';

import actions from './actions';
import {layoutOperations} from "../layout";
import { defaultEmployeeRosterState } from './reducers';
import idConstants from '../../config/idConstants';

const setRosterView = actions.setRosterView;
const setRandomsRosterView = actions.setRandomsRosterView;
const setRandomsPoolRosterView = actions.setRandomsPoolRosterView;
const setRosterSelectedLocation = actions.setRosterSelectedLocation;
const setSortedFilteredRoster = actions.setSortedFilteredRoster;
const setFilteredExcelData = actions.setFilteredExcelData;

const resetRosterViews = () => dispatch => {
  dispatch(setRosterView(defaultEmployeeRosterState.rosterView));
  dispatch(setRandomsRosterView(defaultEmployeeRosterState.randomsRosterView));
  dispatch(setRandomsPoolRosterView(defaultEmployeeRosterState.randomsPoolRosterView));
};

const _getCSADetailValue = (basics, current) => {
  let display = 'ALL';
  current.types.forEach((type) => {
    if (!Object.values(type).every((value) => value)) {
      display = 'SOME'
    }
  });
  return display;
};

const _combineContactAndCSANotificationsList = notificationsData => {
  let contactNotifications = notificationsData ? notificationsData.contacts || [] : [];
  const csaNotificationsList = notificationsData ? notificationsData.csaNotifications || [] : [];
  const basics = notificationsData ? notificationsData.basics || [] : [];
  const notificationTypes = idConstants.notificationTypes;

  const csaResultArray = csaNotificationsList.reduce((accumulator, record) => {
    const contactMatch = accumulator.filter((contact) => contact.email === record.email && contact.clientId === record.clientId);
    let contact;
    if (contactMatch === null || contactMatch.length === 0) {
      const typesArray = notificationTypes.map((type) => {
        const notificationTypeData = {typeName: type.name, typeId: type.typeId};
        basics.forEach((basic) => {
          notificationTypeData[basic.BasName] = false
        });
        return notificationTypeData
      });
      accumulator.push({
        email: record.email,
        clientId: record.clientId,
        clientName: record.clientName,
        UID: record.UID,
        types: typesArray
      });
      contact = accumulator[accumulator.length - 1];
    } else {
      contact = contactMatch[0];
    }
    const index = contact.types.findIndex((type) => type.typeId === record.notifyTypeId);
    if (index !== -1) {
      contact.types[index][record.basicName] = true; //flip the flag for this type of notification
    }
    if (!contact.csaDetail && (record.notifyTypeId === 2 ||
        record.notifyTypeId === 3 ||
        record.notifyTypeId === 4)) {
      contact.csaDetail = true;
    } else if (!contact.csaScores && record.notifyTypeId === 5) {
      contact.csaScores = true;
    }
    return accumulator;
  }, []);

  csaResultArray.forEach((csaContact) => {
    const index = contactNotifications.findIndex(contact => contact.clientId === csaContact.clientId && contact.email === csaContact.email);
    if (csaContact.csaDetail) {//there are some csa detail subscriptions so determine if we have them all.
      csaContact.csaDetail = _getCSADetailValue(basics, csaContact);
    }
    if (index !== -1) {//there is a matching contact notification, so we will append the csa notification data to that one
      contactNotifications[index].types = csaContact.types;
      contactNotifications[index].csaDetail = csaContact.csaDetail || null;
      contactNotifications[index].csaScores = csaContact.csaScores || null;
    } else {
      contactNotifications.push(csaContact); //there is no matching contact notification, so just add this to the end
    }
  });

  return contactNotifications;
};


/**
 * Make axios call with provided query
 * @param query - query
 * @param siteList - list of user sites
 * @param showLoadingPanel (bool) - show loading panel (defaulted to true)
 * @returns {Function} - dispatches searchResults action with query, results, and redirect link
 */
const searchEmployees = (query, siteList, isConcordeUser, showLoadingPanel = true) => dispatch => {
  if (showLoadingPanel) {
    dispatch(layoutOperations.showLoadingPanel());
  }
  axios
  .get(`/v1/employees?search=${query}&sites=${siteList}&isConcordeUser=${isConcordeUser}`)
  .then(res => {
    if (showLoadingPanel) {
      dispatch(layoutOperations.hideLoadingPanel());
    }
    const results = res.data;
    let redirectTo = '/manage/employeesearch';
    if (results.length === 1 && (!isConcordeUser || (isConcordeUser && results[0].importedClient))) {
      redirectTo = `/manage/employees/${results[0].employeeId}`;
    }
    dispatch(actions.searchResults(query, results, redirectTo));
  })
  .catch(err => {
    console.error(err);
    if (showLoadingPanel) {
      dispatch(layoutOperations.hideLoadingPanel());
    }
  })
};

const notificationsChanged = actions.notificationsChanged;

const setNotificationsEditing = actions.setNotificationsEditing;

const setNotificationsFilterBy = actions.setNotificationsFilterBy;

const setNotificationsList = notificationsData => dispatch => {
  dispatch(actions.setNotificationsList(_combineContactAndCSANotificationsList(notificationsData)));
};

/***
 * Get notifications data from list of user Sites
 * @param sites - (array) list of user sites
 * @returns {Function}
 */
const getNotificationsData = sites => dispatch => {
  dispatch(layoutOperations.showLoadingPanel());
  dispatch(actions.setNotificationsLoading(true));
  axios.get(`/v1/clients?notificationsFor=${sites.map(site => site.legacyClientId).join(',')}`)
  .then(response => {
    if (response.status === 200) {
      const notificationsData = response.data;
      //format different types of notifications into one list
      dispatch(actions.setNotificationsList(_combineContactAndCSANotificationsList(notificationsData)));
      dispatch(actions.setNotificationsLoading(false));
      dispatch(layoutOperations.hideLoadingPanel());
    }
  })
  .catch(err => {
    dispatch(actions.setNotificationsLoading(false));
    dispatch(layoutOperations.hideLoadingPanel());
    console.error('failed to load contact information');
  });
};

/**
 * Get notifications data from an email address
 * @param email - (string) notification email address
 * @returns {Function}
 */
const getNotificationsDataForUser = email => dispatch => {
  axios.get(`/v1/clients/0/notifications/${email}`)
  .then(response => {
    const notificationsData = response.data;
    dispatch(actions.setNotificationsList(_combineContactAndCSANotificationsList(notificationsData)))
  })
  .catch(err => {
    console.error(err);
  });
};

/**
 * Save (patch) 1-n notification changes in SQL
 * @param changeLog - (object) list of changes to processes: {contactId: notificationType: notificationValue, etc...}
 * @returns {Function}
 */
const saveNotifications = (changeLog, userId) => dispatch => {
  dispatch(layoutOperations.showLoadingPanel());
  axios
  .patch(`/v1/clients/0/notifications?forUser=${userId}`, changeLog)
  .then(() => {
    dispatch(actions.applyChanges(changeLog));
    dispatch(actions.notificationsChanged({}));
    dispatch(actions.setNotificationsEditing(false));
    dispatch(layoutOperations.hideLoadingPanel());
  })
  .catch(err => {
    dispatch(layoutOperations.hideLoadingPanel());
    console.error(err);
  });
};


// sites

const setSiteTree = actions.setSiteTree;

const setSessionClient = actions.setSessionClient;

const setDisplayIncludeChildren = actions.setDisplayIncludeChildren;

const setSelectedSites = actions.setSitesSelected;

const setSelectedHierarchySites = actions.setSelectedHierarchySites;

const setAutoSelectSite = actions.setAutoSelectSite;

const setSitesSelectorEnabled = actions.setSitesSelectorEnabled;

const setAlertCounts = actions.setAlertCounts;

const setAlertsValues = actions.setAlertsValues;

const setDocumentTabsSingleOrMulti = actions.setDocumentTabsSingleOrMulti;

const getUnscoredMVRs = () => async dispatch => {
  try {
    const res = await axios.get('/v1/mvrscoring/unscored')
    dispatch(actions.setUnscoredMVRs(res.data));
  } catch (err) {
    console.error(err);
  }
};

export default {
  setRosterView,
  setRandomsRosterView,
  setRandomsPoolRosterView,
  resetRosterViews,
  setRosterSelectedLocation,
  setSortedFilteredRoster,
  searchEmployees,
  notificationsChanged,
  setNotificationsEditing,
  getNotificationsData,
  getNotificationsDataForUser,
  setNotificationsList,
  saveNotifications,
  setNotificationsFilterBy,
  setSelectedSites,
  setSiteTree,
  setSessionClient,
  setDisplayIncludeChildren,
  setSelectedHierarchySites,
  setAutoSelectSite,
  setSitesSelectorEnabled,
  setAlertCounts,
  setFilteredExcelData,
  setAlertsValues,
  setDocumentTabsSingleOrMulti,
  getUnscoredMVRs
}