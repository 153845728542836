import React from 'react'

const NotFound = props => {
  return (
      <div>
        <p>Sorry, this page does not exist</p>
      </div>
  )
};

export default NotFound;