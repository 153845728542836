import actions from './actions';
import axios from "axios";
import moment from "moment";

const getCMESearchResults = (query) => dispatch => {
  dispatch(actions.setCMESearchQuery(query));  // store the "search query" in redux state
  dispatch(actions.setCMESearchResults([])); // clear anything prior (helps in the GUI / client to do this)
  axios
  .get(`/v1/cmes?search=${query}`)
  .then(response => {
    if (response.data && response.data.length > 0) {
      response.data.forEach((cme) => {
        if (cme && cme.clinic && cme.clinic.appointment) {
          cme.clinic.appointment = moment.utc(cme.clinic.appointment).format('YYYY-MM-DD H:mm');
        }
      })
    }
    dispatch(actions.setCMESearchResults(response.data)); // set the results in redux state
    dispatch(actions.setCMESearchFetching(false));
  })
  .catch(err => {
    console.error(err);
    dispatch(actions.setCMESearchResults([], err.response.status)); // reset to an empty array on error i.e. "no results"
    dispatch(actions.setCMESearchFetching(false));
  });
};

const getCMETaskList = () => dispatch => {
  axios
  .get('/v1/processes/cmeProcess/tasks')
  .then(res => {
    dispatch(actions.setCMETaskList(mapCamundaVariables(res.data)));
  })
  .catch(err => console.error(err));
};

const resetCMESearch = actions.resetCMESearch;

const setCMETaskListFromSocket = taskList => dispatch => {
  dispatch(actions.setCMETaskList(mapCamundaVariables(taskList)));
};

/**
 * Create property for each Camunda Variable on the root / parent object.
 * After all variables are mapped to the root task object the variables collection is deleted
 */
const mapCamundaVariables = taskList => {
  for (const [, camTask] of Object.entries(taskList)) {
    if (camTask.variables) {
      camTask.variables.forEach(v => {
        let variableValue = v.value;
        if (v.type === 'Date' && v.value) {
          variableValue = moment(v.value);
        }
        // todo: this can't stay. ideally the variable type should be Date in camunda
        if (v.name === 'appointmentDate' && v.value) {
          variableValue = moment(v.value);
        }
        if (v.name === 'received') {
          camTask.daysInQueue = moment(Date.now()).diff(moment(v.value), 'days');
        }
        camTask[v.name] = variableValue;
      });
      delete camTask.variables;
    }
  }
  return Object.keys(taskList).map(key => taskList[key]);
};

const setCustomerCareInterventionItems = actions.setCustomerCareInterventionItems;

export default {
  getCMESearchResults,
  getCMETaskList,
  setCMETaskListFromSocket,
  resetCMESearch,
  setCustomerCareInterventionItems
};