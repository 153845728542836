import types from './types';
import {combineReducers} from 'redux';

const searchDefaultState = {
  query: '',
  results: [],
  fetching: false
};

const taskListsDefaultState = {
  taskListData: [],
  taskListInitialized: false
};

const customerCareInterventionItemsDefaultState = {
  customerCareInterventionItems: []
};

const cmeSearchReducer = (state = searchDefaultState, action) => {
  switch (action.type) {
    case types.SET_CME_SEARCH_QUERY:
      return {
        ...state,
        query: action.payload,
        fetching: true
      };
    case types.SET_CME_SEARCH_RESULTS:
      return {
        ...state,
        results: action.payload.searchResults,
        status: action.payload.status
      };
    case types.SET_CME_FETCHING_RESULTS:
      return {
        ...state,
        fetching: action.payload
      };
    case types.RESET_CME_SEARCH:
      return {
        ...state,
        query: '',
        results: [],
        fetching: false
      };
    default:
      return state;
  }
};

const cmeTaskListsReducer = (state = taskListsDefaultState, action) => {
  switch (action.type) {
    case types.SET_CME_TASKLIST:
      return {
        ...state,
        taskListData: action.payload,
        taskListInitialized: true
      };
    default:
      return state;
  }
};

const cmeCustomerCareInterventionItemsReducer = (state = customerCareInterventionItemsDefaultState, action) => {
  switch (action.type) {
    case types.SET_CUSTOMER_CARE_INTERVENTION_ITEMS:
      return {
        ...state,
        customerCareInterventionItems: action.payload
      };
    default:
      return state;
  }
};

const cmeReducers = combineReducers({
  search: cmeSearchReducer,
  taskLists: cmeTaskListsReducer,
  customerCareIntervention: cmeCustomerCareInterventionItemsReducer
});

export default cmeReducers;