import axios from 'axios';
import _ from 'lodash';

const getCannedCMENotes = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get('/v1/settings/texts?collection=CMENotes', {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(_.sortBy(response.data, 'text'));
      }
      reject('unexpected server response');
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    })
  });
};

const getCannedCMEBadDocReasons = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get('/v1/settings/texts?collection=cmeBadDocReasons', {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(_.sortBy(response.data, 'text'));
      }
      reject('unexpected server response');
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    })
  });
};

const getFeaturesToHide = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get('/v1/settings', {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(response.data ? response.data.featuresToHide : []);
      }
      reject('unexpected server response');
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    })
  });
};


const getCannedInternalDQDeleteReasons = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get('/v1/settings/texts?collection=dqDeleteReasons', {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(_.sortBy(response.data, 'text'));
      }
      reject('unexpected server response');
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    })
  });
};

const getCannedInternalDQBadDocReasons = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get('/v1/settings/texts?collection=dqBadDocReasons', {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(_.sortBy(response.data, 'text'));
      }
      reject('unexpected server response');
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    })
  });
};

const getBasics = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get('/v1/settings/lists?collections=basics', {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(response.data.basics);
      }
      reject('unexpected server response')
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    })
  });
};

const getEmploymentStatus = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/settings/lists?collections=employmentStatus`, {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(response.data.employmentStatus);
      }
      reject('unexpected server response')
    }).catch(err => {
      console.error('failed to retrieve lists');
    });
  });
};

const getLicenseClasses = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/settings/lists?collections=licenseClass`, {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(response.data.licenseClass);
      }
      reject('unexpected server response')
    }).catch(err => {
      console.error('failed to retrieve lists');
    });
  });
};

const getDriversLicenseFormatRules = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/settings/lists?collections=driversLicenseFormatRules`, {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(response.data.driversLicenseFormatRules);
      }
      reject('unexpected server response')
    }).catch(err => {
      console.error('failed to retrieve drivers license format rules', err);
      reject(err);
    });
  });
};

const getAllJobTitles = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/settings/lists?collections=jobTitle`, {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(response.data.jobTitles);
      }
      reject('unexpected server response')
    }).catch(err => {
      console.error('failed to retrieve lists');
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
    });
  });
};

const getAllPrograms = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/settings/lists?collections=programs`, {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(response.data.programs);
      }
      reject('unexpected server response')
    }).catch(err => {
      console.error('failed to retrieve lists');
    });
  });
};

const getStandardDocument = (standardDocName, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/settings/documents/${standardDocName}`, {cancelToken})
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const updateStandardDocument = (standardDocName, docUpdates, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .patch(`/v1/settings/documents/${standardDocName}`, docUpdates, {cancelToken})
    .then(response => {
      return resolve();
    })
    .catch(err => {
      console.error('Failed to update note', err);
      reject(err);
    });
  });
};

const getDocsToHide = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/settings/lists?collections=docTypesToHide`, {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(response.data.docTypesToHide);
      }
      reject('unexpected server response')
    }).catch(err => {
      console.error('failed to retrieve lists');
    });
  });
};

const getDocsToRequire = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/settings/lists?collections=docTypesRequired`, {cancelToken})
    .then(response => {
      if (response.status === 200) {
        return resolve(response.data.docTypesRequired);
      }
      reject('unexpected server response')
    }).catch(err => {
      console.error('failed to retrieve lists');
    });
  });
};

const getIntegrationTypes = (cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get('/v1/settings/lists?collections=IntegrationTypes', {cancelToken})
    .then(response => {
      resolve(response.data.IntegrationTypes);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
}


export default {
  getCannedCMENotes,
  getCannedCMEBadDocReasons,
  getBasics,
  getEmploymentStatus,
  getLicenseClasses,
  getDriversLicenseFormatRules,
  getAllJobTitles,
  getAllPrograms,
  getCannedInternalDQDeleteReasons,
  getCannedInternalDQBadDocReasons,
  getFeaturesToHide,
  getStandardDocument,
  updateStandardDocument,
  getDocsToHide,
  getDocsToRequire,
  getIntegrationTypes
};