const SET_CURRENT_USER = 'SET_CURRENT_USER';
const SET_PASSWORD_POLICY = 'SET_PASSWORD_POLICY';
const GET_SITES_FOR_USER = 'auth/GET_SITES_FOR_USER';
const GET_CLIENT_FOR_USER = 'auth/GET_CLIENT_FOR_USER';
const USER_SET_JUMP_LIST = 'USER_SET_JUMP_LIST';
const SET_TRUE_IDENTITY = 'SET_TRUE_IDENTITY';
const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
const SET_IMPERSONATING_USER_ID = 'SET_IMPERSONATING_USER_ID';
const SET_IMPERSONATE_MODE = 'SET_IMPERSONATE_MODE';

export default {
  SET_CURRENT_USER,
  SET_PASSWORD_POLICY,
  GET_SITES_FOR_USER,
  GET_CLIENT_FOR_USER,
  USER_SET_JUMP_LIST,
  SET_TRUE_IDENTITY,
  SET_USER_PERMISSIONS,
  SET_IMPERSONATING_USER_ID,
  SET_IMPERSONATE_MODE
};