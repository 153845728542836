import types from './types';
import step4DefaultState from './defaultState';

const randomsPoolCreationWizardStep4Reducer = (state = step4DefaultState, action) => {
  switch (action.type) {
    case types.RESET_STEP_4_GLOBAL_STATE: 
      return step4DefaultState;

    case types.SET_STEP4_AVG_POOL_ROSTER_SIZE: 
      return {
        ...state,
        ytdAvgPoolRosterSize: action.payload
      }

    case types.SET_STEP4_FOR_EDITING: 
      return {
        ...state,
        ...action.payload
      }

    case types.SET_STEP4_NUM_SELECTED_DRUG: 
      return {
        ...state,
        numberSelectedDrug: action.payload
      }

    case types.SET_STEP4_NUM_TESTED_DRUG: 
      return {
        ...state,
        numberTestedDrug: action.payload
      }

    case types.SET_STEP4_NUM_SELECTED_ALCOHOL: 
      return {
        ...state,
        numberSelectedAlcohol: action.payload
      }

    case types.SET_STEP4_NUM_TESTED_ALCOHOL: 
      return {
        ...state,
        numberTestedAlcohol: action.payload
      }

    default:
        return state;
  }
}

export default randomsPoolCreationWizardStep4Reducer;