import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip} from 'reactstrap';
import {Rnd} from 'react-rnd';
import SearchComponent from '../../common/SearchField/searchComponent';
import CMEResearchResultsComponent from './components/cmeResearchResultsComponent';
import CMEResearchAuditTrailComponent from './components/cmeResearchAuditTrailComponent'
import CMEResearchEmployeeInfoComponent from './components/cmeResearchEmployeeInfoComponent';
import CMEResearchOrdersComponent from './components/cmeResearchOrdersComponent';
import DocumentListPane from '../../common/DocumentListViewer/components/documentListPaneComponent';
import PDFViewer from '../../common/PDF/PDFViewer';

const cmeResearchModalComponent = props => {

  return (
      <Modal isOpen={true} size={'lg'} style={{maxWidth: '1385px'}}>
        <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
          <ModalHeader className={'cursor-move cme-research-modal'}>
            <div className={'row'}>
              <div className={'col-sm-8'}>CME Tool</div>
              <div className={'col-sm-4'}>
                <SearchComponent
                    placeholder={'Search'}
                    onSearchInputChanged={props.onSearchInputChanged}
                    onSearchButtonClick={props.onSearchButtonClick}
                />
              </div>
            </div>
          </ModalHeader>
          {(props.searchResults || props.orderResults) && <ModalBody>
            {props.employeeInfo && <CMEResearchEmployeeInfoComponent employeeInfo={props.employeeInfo}
                                                                     onShowDocuments={props.onShowDocuments}
                                                                     showDocuments={props.showDocuments}/>
            }
            {props.searchResults && <div className={'mt-2'}><CMEResearchResultsComponent
                results={props.searchResults}
                selectionChanged={props.selectionChanged}
                employeeId={props.employeeId}
            /></div>}
            {props.orderResults && !props.showDocuments &&
            <div className={'mt-2'}>
              <CMEResearchOrdersComponent
                  handleItemSelected={props.orderSelectionChanged}
                  onUpdateOrder={props.handleUpdateOrder}
                  results={props.orderResults}
                  employeeInfo={props.employeeInfo}
                  onOrderChangedCallback={props.onOrderChangedCallback}
                  onOrderChanged={props.onOrderChanged}
                  userEmail={props.userEmail}
              />
              {props.auditTrail && <div className={'mt-2'}>
                <CMEResearchAuditTrailComponent data={props.auditTrail}/>
                <div className={'input-group pt-3 d-flex justify-content-end'}>
                  <div className={"col-sm-8 row offset-sm-4"}>
                    <input type={'text'} className={'ml-1 col-sm-10 form-control form-control-sm border-info'}
                           value={props.newMessageText}
                           onChange={props.onChangeMessageText}
                           placeholder="Type new message here"
                    />
                    <button type={'button'} className={' ml-2 btn btn-sm btn-secondary'}
                            data-toggle="tooltip" id={'newMessageButton'}
                            onClick={props.onAddMessage}>
                      Add Message
                    </button>
                    <UncontrolledTooltip placement="top" target={'newMessageButton'} container='#root'>
                      Add a new message to the audit trail
                    </UncontrolledTooltip>
                  </div>
                </div>
              </div>}
            </div>}
            {props.showDocuments &&
            <div className={'mt-2'}>
              <div className={'pl-1'}>
                <DocumentListPane data={props.docList} clickRowHandler={props.documentListClickRowHandler}
                                  employeeInfo={props.employeeInfo}/>
              </div>
              {props.selectedDocumentId &&
              <div><PDFViewer
                  docId={props.selectedDocumentId}
                  url={`/v1/employees/${props.employeeId}/documents/${props.selectedDocumentId}`}
                  onPageCountChange={() => {
                  }}
                  rotatePage={() => {
                  }}
                  allowEdit={true}
              /></div>}
            </div>}
          </ModalBody>}
          <ModalFooter>
            <button type={'button'} className={'btn btn-sm btn-secondary'} onClick={props.onHideModal}>
              Close
            </button>
          </ModalFooter>
        </Rnd>
      </Modal>
  );
};

export default cmeResearchModalComponent;