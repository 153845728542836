import React from 'react';
import moment from 'moment';
import ReactTable from 'react-table';
import CBReactTablePagination from '../../../common/CBReactTablePagination/CBReactTablePagination';
import PageMessage from '../../../common/PageMessage/pageMessageComponent';
import SocialSecurityNumberDisplayContainer
  from '../../../common/SocialSecurityNumber/SocialSecurityNumberDisplay/SocialSecurityNumberDisplayContainer';

const cmeResearchResultsComponent = props => {
  const results = props.results || [];

  const employeeColumns = [
    {
      id: 'colReportTitle',
      Header: (<span className={'h6 mt-1 mb-0'}>{'Employee Results'}</span>),
      columns: [
        {
          id: 'colId',
          Header: '#',
          accessor: 'employeeId',
          className: 'text-left',
          headerClassName: 'text-left',
          show: false
        },
        {
          id: 'colName',
          Header: 'Employee Name',
          Cell: ({original}) => `${original.firstName} ${original.lastName}`,
          accessor: 'LastName',
          headerClassName: 'text-left'
        },
        {
          id: 'colEmployerName',
          Header: 'Employer Name',
          accessor: 'employerName',
          className: 'text-left',
          headerClassName: 'text-left'
        },
        {
          id: 'colDOB',
          Header: 'DOB',
          accessor: 'dob',
          Cell: ({value}) => value ? moment(value).utc().format('M/D/YYYY') : '',
          className: 'text-left',
          headerClassName: 'text-left'
        },
        {
          id: 'colSSN',
          Header: 'SSN',
          accessor: 'ssn',
          className: 'text-left',
          headerClassName: 'text-left',
          Cell: ({value}) => {
            return (
                <SocialSecurityNumberDisplayContainer ssn={value} redact={false}/>
            );
          }
        },
        {
          id: 'colLicense',
          Header: 'License',
          accessor: 'licence',
          className: 'text-left',
          headerClassName: 'text-left'
        },
        {
          id: 'colJobTitle',
          Header: 'Job Title',
          accessor: 'jobTitle',
          className: 'text-left',
          headerClassName: 'text-left'
        },
        {
          id: 'colStatus',
          Header: 'Status',
          accessor: 'employmentStatus',
          className: 'text-left',
          headerClassName: 'text-left'
        }
      ]
    }];

  const maxRows = 5;
  const dataLength = results ? results.length : 0;
  let defaultPageSize = dataLength <= maxRows ? dataLength : maxRows;
  if (defaultPageSize === 0) {
    defaultPageSize = 3;
  }

  return (
      <div className="bg-white mb-4 mt-2">
        <ReactTable
            key={defaultPageSize}
            className={'-striped -highlight -cursor-pointer'}
            columns={employeeColumns}
            data={results}
            defaultPageSize={defaultPageSize}
            showPagination={dataLength > defaultPageSize}
            PaginationComponent={CBReactTablePagination}
            noDataText={
              !results
                  ? <PageMessage color={'white'} bgColor={'info'} text={'Retrieving results...'}/>
                  : results.length === 0 &&
                  <PageMessage color={'black'} bgColor={'warning'} text={'There are no matching results'}/>
            }
            filterable={false}
            getTrProps={
              (state, rowInfo, column) => {
                if (!rowInfo) return {};
                if (!rowInfo.original) return {};
                return {
                  className: rowInfo.original.selected ? 'selected' : ''
                }
              }
            }
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  // If it's an icon, don't trigger the row click (the icon has its own onClick)
                  if (!rowInfo) return;
                  if (!rowInfo.original) return;
                  props.selectionChanged(e, rowInfo.original);
                  if (handleOriginal) {
                    handleOriginal();
                  }
                }
              };
            }}
        />
      </div>
  )
};

export default cmeResearchResultsComponent;