import actions from './actions';

const setPathMapping = (path, url, text) => dispatch => {
  dispatch(actions.setPathMapping(path, url, text));
};

const deletePathMapping = (path) => dispatch => {
  dispatch(actions.deletePathMapping(path));
};

export default {
  setPathMapping,
  deletePathMapping
}
