import React, {Component} from 'react';
import {connect} from 'react-redux';

import LoadingPanelComponent from './loadingPanelComponent';

export class LoadingPanelContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: false
    }
  }

  componentDidMount() {
    this.setState({spinning: this.props.layout.loadingPanel.instances > 0});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.layout.loadingPanel.instances !== prevProps.layout.loadingPanel.instances) {
      this.setState({spinning: this.props.layout.loadingPanel.instances > 0});
    }
  }

  render() {
    return (
        this.state.spinning ? <LoadingPanelComponent/> : null
    );
  }

}

const mapStateToProps = state => ({
  layout: state.layout
});

export default connect(mapStateToProps)(LoadingPanelContainer);