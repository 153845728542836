import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import TransferDQFile from './transferDQFileComponent';
import axios from "axios";
import {alertsOperations} from '../../../../../../ducks/alerts';
import {modalOperations} from "../../../../../../ducks/modal";
import {Rnd} from "react-rnd";
import _ from 'lodash';
import {layoutOperations} from '../../../../../../ducks/layout';

class TransferEmployeeModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    this.loadData();
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
    this.props.resetLoadingPanel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(this.props.employeeInfo, prevProps.employeeInfo)) {
      this.loadData();
    }
  }

  loadData = () => {
    //the props are coming in on the info prop of the modal container, so map them to the ones we need
    this.setState({
      employeeInfo: this.props.info.employeeInfo,
      dropDownLists: this.props.info.dropDownLists,
      selectedTargetSite: this.props.info.selectedTargetSite,
      selectedJobTitle: this.props.info.selectedJobTitle,
      selectedEmploymentStatus: this.props.info.selectedEmploymentStatus
    });
  };

  handleTransferSelect = (propertyName, value) => {
    this.setState({[propertyName]: value});
  };

  performTransfer = () => {
    const employeeId = this.state.employeeInfo.employeeId;
    const {selectedTargetSite, selectedJobTitle, selectedEmploymentStatus} = this.state;
    this.props.showLoadingPanel();
    this.setState({transferInProgress: true}, () => {
      axios.patch(`/v1/employees/${employeeId}`, {
        transfer: {
          oldClientId: this.state.employeeInfo.legacyClientId,
          newClientId: selectedTargetSite.value,
          jobTitleId: selectedJobTitle.value,
          employmentStatusId: selectedEmploymentStatus.value
        }
      }, {cancelToken: this.cancelSource.token})
      .then(result => {
        this.props.hideLoadingPanel();
        this.props.hideModal(true);
        this.props.addAlert('The driver file was successfully transferred');
        if (result?.data?.length) {
          this.props.addAlert(result.data, 'info');
        }
      })
      .catch(err => {
        this.props.hideLoadingPanel();
        this.props.hideModal(true);
        this.props.addAlert('The transfer completed with issues. Please contact customer service.', 'danger');
        console.error(err);
      });
    });
  };

  cancelTransfer = () => {
    this.props.hideModal(false);
  };

  render() {
    const {
      employeeInfo, dropDownLists, selectedTargetSite, selectedJobTitle,
      selectedEmploymentStatus, transferInProgress
    } = this.state;

    if (!employeeInfo) {
      return null;
    }

    return (
        <Modal isOpen={true}>
          <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
            <ModalHeader className={'cursor-move'}>Transfer {employeeInfo.firstName} {employeeInfo.lastName} to
              Another Work
              Location</ModalHeader>
            <ModalBody>
              <TransferDQFile
                  currentSite={employeeInfo.employerName}
                  allowedTargetSites={this.props.auth.user.sites.filter(site => site.legacyClientId !== employeeInfo.legacyClientId && !site.deactivated)}
                  selectedSite={selectedTargetSite}
                  handleSelect={this.handleTransferSelect}
                  jobTitles={dropDownLists.jobTitles}
                  selectedJobTitle={selectedJobTitle}
                  employmentStatuses={dropDownLists.employmentStatus}
                  selectedEmploymentStatus={selectedEmploymentStatus}
              />
            </ModalBody>
            <ModalFooter>
              <button
                  className={'btn btn-primary btn-sm'}
                  onClick={this.performTransfer}
                  disabled={selectedTargetSite.value === -1 || transferInProgress}
              >
                Transfer
              </button>
              <button className={'btn btn-secondary btn-sm'} onClick={this.cancelTransfer}>Cancel</button>
            </ModalFooter>
          </Rnd>
        </Modal>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  addAlert: alertsOperations.addAlert,
  showLoadingPanel: layoutOperations.showLoadingPanel,
  hideLoadingPanel: layoutOperations.hideLoadingPanel,
  resetLoadingPanel: layoutOperations.resetLoadingPanel
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferEmployeeModalContainer);