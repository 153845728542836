import React, {Fragment} from 'react';
import Select from 'react-select';

const dotNumberModalComponent = props => {

  //const dotList = props.dotList.map((item) => item);
  return (
      <Fragment>
        <div className={'form-group row'}>
          <label className={'col-sm-3 col-form-label'}>DOT Number</label>
          <div className={'col-sm-9'}>
            <Select options={props.dotList} isMulti={true} onChange={props.onDOTListChange}/>
          </div>
        </div>
      </Fragment>
  );
};

export default dotNumberModalComponent;