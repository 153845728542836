import axios from 'axios';
import moment from 'moment';

const sendAuthorizationEmail = (orderId, orderType, email, employerName, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.post(`/v1/authorizations/${orderId}/email`, {orderType, email, employerName}, {cancelToken})
    .then(response => {
      resolve(response.data);
    })
    .catch(err => reject(err));
  });
};

const getOpenOrders = (selectedSites, forEmployee, onlyPhysicals, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/orders?sites=${selectedSites}&openOrders=true${forEmployee}${onlyPhysicals ? '&onlyPhysicals' : ''}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to get employee open orders', err);
      reject(err);
    });
  });
};

const getPreviousOrders = (selectedSites, forEmployee, startDate, endDate, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/orders?sites=${selectedSites}${forEmployee ? `&forEmployee=${forEmployee}` : `&startDate=${startDate}&endDate=${endDate}`}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to get previous orders', err);
      reject(err);
    });
  });
};

/**
 * Get drug tests for an employee from IMS
 * @param {string} legacyEmployeeId - The employee's legacy ID from MongoDB the corresponds to the IMS Utility API ID
 * @param {Object} cancelToken - Axios cancel token
 */
const getDrugTestsForEmployee = async (legacyEmployeeId, cancelToken = null) => {
  try {
    const drugTests = await axios.get(`/v1/orders/getDrugTestsForEmployee/${legacyEmployeeId}`, {cancelToken});
    return drugTests;
  } catch (err) {
    console.error('Failed to get drug tests for employee', err);
    throw err;
  }
};

/**
 * Reopens drug order in IMS
 * @param {String} orderId - the order ID from IMS
 * @param {Object} cancelToken - the axios cancel token
 * @returns 
 */
const reopenOrder = async (orderId, cancelToken = null) => {
  try {
    const reopenedOrder = await axios.patch(`/v1/orders/${orderId}/reopen`, {cancelToken});
    return reopenedOrder;
  } catch (err) {
    console.error('Failed to reopen order', err);
    throw err;
  }
}


const searchOrders = (queryString, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.get(`/v1/orders?${queryString}`, {cancelToken})
    .then(response => {
      if (!response.data.hasOwnProperty("orderResults")) {
        return resolve(response.data);
      }
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to get orders', err);
      reject(err);
    });
  });
};


const updateOrder = (orderId, orderStatusChanges, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .patch(`/v1/orders/${orderId}`, orderStatusChanges, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to update order', err);
      reject(err);
    });
  });
};

const cancelOrder = (order, email, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    const orderUpdates = {
      "orderId": order.orderId,
      "medicalExamOrderId": order.orderId,
      "userEmail": email,
      "orderType": order.orderType,
      "updateLog": {
        "updateStatus": {
          "orderStatus": "Canceled",
          "orderStatusId": 6,
          "auditMessage": "Status changed to Canceled"
        }
      },
      "orderSource": order.orderSource
    };
    axios
    .patch(`/v1/orders/${order.orderId}`, orderUpdates, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to update order', err);
      reject(err);
    });
  });
};

const getAuditTrail = (orderId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!orderId) {
      return resolve([]);
    }
    axios.get(`/v1/orders/${orderId}/auditTrail`, {cancelToken})
    .then(response => {
      let auditTrail = response.data;
      for (let i = 0; i < auditTrail.length; i++) {
        auditTrail[i].AuditTime = moment(auditTrail[i].AuditTime).format('M/D/YYYY h:mm a')
      }
      return resolve(auditTrail);
    })
    .catch(err => {
      if (!axios.isCancel(err)) {
        return reject(err);
      }
    });
  });
};

const addAuditTrail = (orderId, message, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.post(`/v1/orders/${orderId}/auditTrail`, {message}, {cancelToken})
    .then(response => {
      return resolve();
    })
    .catch(err => {
      return reject(err);
    });
  });
};

const refreshMVR = (MVRId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios.patch(`/v1/orders/${MVRId}/refreshmvr`, {}, {cancelToken})
    .then(response => resolve())
    .catch(reject)
  });
};



// CME = Certified Medical Exam
const CME = {
  /**
   * Get CME (Certified Medical Exam) orders
   * @param {*} queryString 
   * @param {*} cancelToken 
   * @returns 
   */
  getOrders (queryString, cancelToken = null) {
    return new Promise((resolve, reject) => {
      
      const url = `/v1/orders/cme?${queryString}`;

      axios.get(url, {cancelToken})
      .then(response => {
        return resolve(response.data);
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          return reject(err.message);
        }
        console.error('Failed to get orders', err);
        reject(err);
      });
    });
  }
}


export default {
  sendAuthorizationEmail,
  getOpenOrders,
  updateOrder,
  cancelOrder,
  getPreviousOrders,
  getDrugTestsForEmployee,
  reopenOrder,
  searchOrders,
  getAuditTrail,
  addAuditTrail,
  refreshMVR,
  CME
};