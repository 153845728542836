import types from './types';

const initialState = {
  displayModal: null, //indicates which modal to display. as a string
  info: null, //the data that is required by the specified modal
  refreshData: false, //a flag to indicate if data may need to be refreshed on the original page
  dialogAccepted: false //a flag to indicate if the user clicked on the accept button
};

const modalReducer = (state = initialState, action) => {
  let displayModal, info, refreshData, responseInfo;
  switch (action.type) {
    case types.SHOW_MODAL:
      displayModal = action.payload.displayModal;
      refreshData = false;
      info = action.payload.info;
      return {
        ...state,
        refreshData,
        displayModal,
        info
      };
    case types.HIDE_MODAL:
      displayModal = info = null;
      refreshData = action.payload.refreshData;
      responseInfo = action.payload.responseInfo;
      return {
        ...state,
        displayModal,
        info,
        refreshData,
        responseInfo
      };
    default:
      return state;
  }
};

export default modalReducer;