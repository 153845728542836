import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import axios from "axios";
import {modalOperations} from "../../../../../../../ducks/modal";
import CopySettingsModal from './copySettingsModalComponent'
import {alertsOperations} from "../../../../../../../ducks/alerts";
import _ from "lodash";
import {Rnd} from "react-rnd";
import {layoutOperations} from '../../../../../../../ducks/layout';
import clientsService from '../../../../../../../services/clientsService/clientsService';

class CopySettingsModalContainer extends Component {
  constructor(props) {
    super(props);

    //the props are coming in on the info prop of the modal container, so map them to the ones we need
    this.state = {
      legacyClientId: parseInt(props.info.legacyClientId, 10),
      clientId: props.info.mongoId,
      checkboxes: {},
      fullClient: null
    };
  }

  componentDidMount() {
    if (this.state.clientId) {
      this.loadFullClient(this.state.clientId);
    }
    this.cancelSource = axios.CancelToken.source();
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (!this.props.auth.user || !this.props.auth.user.sites) {
      return;
    }
    if (!prevProps.auth.user || !prevProps.auth.user.sites ||
        !_.isEqual(this.props.auth.user.sites, prevProps.auth.user.sites)) {
      this.loadFullClient(this.props.info.mongoId);
      this.setState({legacyClientId: parseInt(this.props.info.legacyClientId, 10), clientId: this.props.info.mongoId});
    }
    if (prevProps.info.legacyClientId !== this.props.info.legacyClientId) {
      this.loadFullClient(this.props.info.mongoId);
      this.setState({legacyClientId: parseInt(this.props.info.legacyClientId, 10), clientId: this.props.info.mongoId});
    }
  }

  loadFullClient = (mongoId) => {
    this.props.showLoadingPanel();
    clientsService.getClient(mongoId)
    .then((result) => {
      this.setState({fullClient: result});
      this.props.hideLoadingPanel();
    })
    .catch(() => {
      this.props.addAlert('Failed to load the full client', 'danger');
      this.props.hideLoadingPanel();
    })
  }

  handleCopySelect = (propertyName, value) => {
    this.setState({[propertyName]: value});
  };

  handleCheckBoxChange = (e) => {
    const {checkboxes} = this.state;
    checkboxes[e.target.name] = e.target.checked;
    this.setState({checkboxes});
  };


  cancelCopy = () => {
    this.props.hideModal(false);
  };

  performCopy = (e) => {
    e.preventDefault();
    const {checkboxes} = this.state;    
    let fields = !!checkboxes.docTypes ? ['docTypes'] : [];
    if (!!checkboxes.users) {
      fields.push('users');
    }
    if (!!checkboxes.notifications) {
      fields.push('notifications');
    }
    if (!!checkboxes.medcardsubmissions) {
      fields.push('medcardsubmissions');
    }
    if (!!checkboxes.drivermonitoring) {
      fields.push('drivermonitoring');
    }
    this.props.showLoadingPanel();
    clientsService.copySettingsFromLocation(this.state.clientId, this.state.selectedTargetSite.value, fields, this.cancelSource.token)
    .then(result => {
      this.props.hideModal(true);
      this.props.addAlert('The settings were created successfully.', 'success');
      this.props.hideLoadingPanel();
    })
    .catch(err => {
      this.props.hideModal(true);
      this.props.addAlert('The settings were not copied.' + err.response.data, 'danger');
      this.props.hideLoadingPanel();
      console.log(err);
    });
  };

  render() {
    const {
      legacyClientId, selectedTargetSite, checkboxes, fullClient
    } = this.state;

    const currentSiteList = this.props.auth.user.sites.filter(site => site.legacyClientId === legacyClientId);
    const currentSite = currentSiteList && currentSiteList.length > 0 ? currentSiteList[0] : null;
    if (!currentSite || !currentSite.parent) {
      this.props.hideModal(false);
      this.props.addAlert('You cannot copy to this location', 'danger');
      return null;
    }

    if (!fullClient) {
      return null;
    }
    const allowedTargetSites = this.props.auth.user.sites.filter(site =>
        ((fullClient.parent && fullClient.parent.children.includes(site._id))
            || fullClient.parent._id === site._id)
        && site._id !== fullClient._id
    );

    const copyEnabled = this.state.selectedTargetSite && (this.state.checkboxes.notifications || 
                                                          this.state.checkboxes.users || 
                                                          this.state.checkboxes.docTypes ||
                                                          this.state.checkboxes.medcardsubmissions ||
                                                          this.state.checkboxes.drivermonitoring
                                                          );

    return (
        <Modal isOpen={true}>
          <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
            <ModalHeader className={'cursor-move'}>Copy Settings from Another Location</ModalHeader>
            <ModalBody>
              <CopySettingsModal
                  allowedTargetSites={allowedTargetSites}
                  handleSelect={this.handleCopySelect}
                  currentSite={fullClient}
                  selectedTargetSite={selectedTargetSite}
                  checkboxes={checkboxes}
                  handleCheckBoxChange={this.handleCheckBoxChange}
              />
            </ModalBody>
            <ModalFooter>
              <button className={'btn btn-primary btn-sm'} id='copy' onClick={this.performCopy}
                      disabled={!copyEnabled}>Copy
              </button>
              <button className={'btn btn-secondary btn-sm'} onClick={this.cancelCopy}>Cancel</button>
            </ModalFooter>
          </Rnd>
        </Modal>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  addAlert: alertsOperations.addAlert,
  showLoadingPanel: layoutOperations.showLoadingPanel,
  hideLoadingPanel: layoutOperations.hideLoadingPanel
};

export default connect(mapStateToProps, mapDispatchToProps)(CopySettingsModalContainer);