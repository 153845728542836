import constants from '../helperFiles/constants';

const step3DefaultState = {
  existingPoolNames: null,
  testingAuthoritiesAndRates: {},
  testingAuthoritiesList: [],
  testingAuthoritySelected: '',
  percentageDrug: '',
  percentageAlcohol: '',
  poolNameAlreadyExists: false,
  selectionFrequenciesList: constants.selectionFrequenciesList,
  selectionFrequencySelected: {value: 4, label: 'Quarterly'},
  poolName: '',
}

export default step3DefaultState;