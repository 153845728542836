import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter, Progress} from 'reactstrap';
import axios from "axios";
import {modalOperations} from "../../../../../../../ducks/modal";
import _ from "lodash";
import {Rnd} from "react-rnd";
import async from 'async';

class PushSettingsModalContainer extends Component {
  constructor(props) {
    super(props);

    //the props are coming in on the info prop of the modal container, so map them to the ones we need
    this.state = {
      legacyClientId: parseInt(props.info.legacyClientId, 10),
      clientId: props.info.mongoId,
      checkboxes: {}
    };
  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
    this.startSettingsPush();
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }

  _siteList = [];

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (!this.props.auth.user || !this.props.auth.user.sites) {
      return;
    }
    if (!prevProps.auth.user || !prevProps.auth.user.sites ||
        !_.isEqual(this.props.auth.user.sites, prevProps.auth.user.sites)) {
      this.setState({
        legacyClientId: parseInt(this.props.info.legacyClientId, 10),
        clientId: this.props.info.mongoId
      }, () => {
        this.startSettingsPush()
      });
    }
    if (prevProps.info.legacyClientId !== this.props.info.legacyClientId) {
      this.setState({
        legacyClientId: parseInt(this.props.info.legacyClientId, 10),
        clientId: this.props.info.mongoId
      }, () => {
        this.startSettingsPush()
      });
    }
  }

  getListOfChildren = (subTree, include) => {
    subTree.children.forEach((child) => {
      if (include) {
        this._siteList.push({
          legacyClientId: child.legacyClientId,
          id: child._id,
          name: child.name
        });
        this.getListOfChildren(child, true);
      } else {
        this.getListOfChildren(child, child._id === this.state.clientId);
      }
    });
  };

  startSettingsPush = () => {
    //get all the children.
    this._siteList = [];
    this.getListOfChildren(this.props.dqf.sites.siteTree, this.props.dqf.sites.siteTree._id === this.state.clientId);
    const siteCount = this._siteList.length;
    this.setState({siteCount, progress: 0, siteList: this._siteList}, () => {
      this.pushSettings(this._siteList, siteCount);
    });
  };

  pushSettings = (sitesToPushSettingsToList, siteCount) => {
    const errorList = [];
    async.eachOfSeries(sitesToPushSettingsToList, (site, e, cb) => {
      axios.patch(`/v1/clients/${site.id}?settingsFrom=${this.state.legacyClientId}&fields=docTypes,medcardsubmissions,drivermonitoring&legacy=true`, {}, {cancelToken: this.cancelSource.token})
      .then(() => {
        const progress = Math.floor(((e + 1) / siteCount) * 100);
        this.setState({progress});
        return cb(null);
      })
      .catch(err => {
        console.log(err);
        // Depending on where we generate an error on the backend, the 'response' property can have an 'errorText' property, an 'error' property, a 'message' property, or none of the above
        let errorMsg, detailedErrorMessage;
        if(err.response && err.response.data){
          if(err.response.data.errorText){ 
            errorMsg = err.response.data.errorText;
          } else if(err.response.data.error){
            errorMsg = err.response.data.error;
          } else {
            errorMsg = 'Unknown error while completing settings copy';
          };
          if(err.response.data.message){ 
            detailedErrorMessage = err.response.data.message;
          };
        } else {
          errorMsg = 'Unknown error while completing settings copy';
        };
        errorList.push({legacyClientId: site.legacyClientId, id: site.id, error: errorMsg, detailedErrorMessage, name: site.name});
        const progress = Math.floor(((e + 1) / siteCount) * 100);
        this.setState({progress});
        return cb(null);
      });
    }, err => {
      this.setState({complete: true, errorList});
    });
  };

  retrySettingsPush = () => {
    this.setState({progress: 0, siteList: this.state.errorList, siteCount: this.state.errorList.length, complete: false}, () => {
      this.pushSettings(this.state.errorList, this.state.errorList.length);
    });
  };

  finishCopy = () => {
    this.setState({
      complete: null,
      errorList: null,
      progress: null,
      siteCount: null,
      siteList: []
    });
    this.props.hideModal(true, {refreshData: true});
  };


  render() {
    const {
      progress, siteList, siteCount, errorList, complete
    } = this.state;

    if (!siteList) {
      return null;
    }
    return (
        <Modal isOpen={true}>
          <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
            <ModalHeader className={'cursor-move'}>
              Push{progress || progress === 0 ? 'ing' : ''} settings to the {siteList.length} child location{siteCount > 1 && 's'} 
            </ModalHeader>
            <ModalBody>
              {(progress || progress === 0) && !complete ?
                <>
                  <div>Progress: {progress}% of {siteCount} location{siteCount > 1 ? 's' : ''}</div>
                  <Progress value={progress}/>
                </>
                :
                <>
                  <div> {errorList.length === 0 && 'Settings copy completed successfully'}</div>
                  {errorList.length > 0 && 
                    <div>
                      <div>The following {errorList.length} locations failed to have the settings completely applied:</div>
                      <div className='list-group mt-1'> 
                        {errorList.map((location, idx) => (
                          <div key={`error${idx}`} className='list-group-item p-1 list-group-item-danger'>
                            <div><span className='font-weight-bold'>Location:</span> {location.name}</div>
                            <div><span className='font-weight-bold'>Error:</span> {location.error}</div>
                            {location.detailedErrorMessage && <div><span className='font-weight-bold'>Details:</span> {location.detailedErrorMessage}</div>}
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                </>
              }
            </ModalBody>
            <ModalFooter>
              {complete &&
              <> 
                {this.state.errorList && this.state.errorList.length > 0 && <button className={'btn btn-secondary btn-sm'} onClick={this.retrySettingsPush}>Retry Sites That Failed</button>}
                <button className={'btn btn-primary btn-sm'} onClick={this.finishCopy}>OK</button>
              </>}
            </ModalFooter>
          </Rnd>
        </Modal>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  dqf: state.dqf
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PushSettingsModalContainer);