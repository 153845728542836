import _ from 'lodash';

import types from './types';
import allStepsDefaultState from './defaultState';

const randomsPoolCreationWizardAllStepsReducer = (state = allStepsDefaultState, action) => {
  let currentStep = state.currentStep;
  const stepsCopy = _.cloneDeep(state.steps);

  switch (action.type) {
    // =======================================================================================
    //                        Randoms Pool Creation Wizard - All Steps
    // =======================================================================================
    case types.INCREMENT_CURRENT_STEP_BY_1:
      // the status updates are needed for the rc-steps library to correctly show what step the user is on.
      stepsCopy[currentStep].status = 'finish';
      stepsCopy[currentStep + 1].status = 'process';
      return {
        ...state,
        currentStep: currentStep += 1,
        okToProceedToNextStep: false, // When users move on to the next step we'll need to re-check if they can proceed to the step after that
        steps: stepsCopy
      }

    case types.DECREMENT_CURRENT_STEP_BY_1:
      // the status updates are needed for the rc-steps library to correctly show what step the user is on.
      stepsCopy[currentStep].status = 'wait';
      stepsCopy[currentStep - 1].status = 'process';
      return {
        ...state,
        currentStep: currentStep -= 1,
        steps: stepsCopy
      }

    case types.RESET_ALL_STEPS_GLOBAL_STATE:
      return allStepsDefaultState;

    case types.SET_POOL_TO_EDIT:
        return {
          ...state,
          poolToEdit: action.payload,
          showRandomsPoolWizard: true
        }

    case types.SET_RANDOMS_POOL_WIZARD_CRITICAL_ERROR:
      return {
        ...state,
        criticalErrorEncountered: true
      }

    case types.SET_RANDOMS_POOL_WIZARD_HAVE_FETCHED_DATA:
      return {
        ...state,
        haveFetchedDataForWizard: true
      }

    case types.SET_RANDOMS_POOL_WIZARD_OK_TO_PROCEED:
      return {
        ...state,
        okToProceedToNextStep: action.payload
      }

    case types.SET_SHOW_RANDOMS_POOL_WIZARD:
      return {
        ...state,
        showRandomsPoolWizard: action.payload
      }

    default:
      return state;
  }
}

export default randomsPoolCreationWizardAllStepsReducer;