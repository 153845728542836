import actions from './actions';

const showModal = (displayModal, info) => dispatch => {
  dispatch(actions.showModal(displayModal, info));
};

const hideModal = (refreshData, responseInfo) => dispatch => {
  dispatch(actions.hideModal(refreshData, responseInfo));
};

export default {
  showModal,
  hideModal
}
