import types from './types';
import {combineReducers} from 'redux';
import moment from 'moment';

const taskListsDefaultState = {
  taskListData: [],
  taskListInitialized: false
};

const dqTaskListsReducer = (state = taskListsDefaultState, action) => {
  const now = moment(Date.now());
  switch (action.type) {
    case types.SET_DQ_TASKLIST:
      return {
        ...state,
        taskListData: action.payload.taskList,
        taskListInitialized: true,
        taskListProcessed: action.payload.taskListProcessed,
        timestamp: now
      };
    default:
      return state;
  }
};

const tableSettingsDefaultState = {
  getExternalFilesTable: { // DQ Worklists > Other > 'Retrieve Pre-Hire Docs'
    sorting: [],
    pageIndex: 0,
    pageSize: 10
  }
};

const dqTableSettingsReducer = (state = tableSettingsDefaultState, action) => {
  // We could combine these into one action type like UPDATE_DQ_TABLE_SETTINGS, but I think leaving them seperate
  // will give us more granularity should one fail as several different operations call them, and it might help to 
  // trace down which operation is the culprit if we know that 'SET_DQ_TABLE_PAGE_INDEX' was the last dispatch as 
  // opposed to a general 'UPDATE_DQ_TABLE_SETTINGS'
  switch (action.type) {
    case types.SET_DQ_TABLE_PAGE_INDEX: 
      if(action.payload.table === 'getExternalFilesTable'){
        return {
          ...state,
          getExternalFilesTable: {
            ...state.getExternalFilesTable,
            pageIndex: action.payload.pageIndex
          }
        }
      } // else (some other table name)
      break;
    case types.SET_DQ_TABLE_PAGE_SIZE:
      if(action.payload.table === 'getExternalFilesTable'){
        return {
          ...state,
          getExternalFilesTable: {
            ...state.getExternalFilesTable,
            pageIndex: action.payload.pageIndex,
            pageSize: action.payload.pageSize
          }
        }
      }
      break;

    case types.SET_DQ_TABLE_SORTING:
      if(action.payload.table === 'getExternalFilesTable'){
        return {
          ...state,
          getExternalFilesTable: {
            ...state.getExternalFilesTable,
            sorting: {
              columnId: action.payload.columnId,
              desc: action.payload.desc
            }
          }
        }
      }
      break;

    default:
      return state;
  }
};

const dqReducers = combineReducers({
  taskLists: dqTaskListsReducer,
  tableSettings: dqTableSettingsReducer
});

export default dqReducers;