import React, {Fragment} from 'react';
import {UncontrolledTooltip} from 'reactstrap';
import MultiFileUploadContainer from './multiFileUploadComponent';

const drugAlcoholUploadModalComponent = props => {

  return (
      <Fragment>
        {(props.checklist.q1 || props.checklist.q2 || props.checklist.q5) &&
        <div className="pl-3 pb-2">
          <div className="pb-1 font-weight-bold">The information required to be reported under all questions listed
            above must include, as applicable:
          </div>
          <div className="pl-1">
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(i)</div>
              <div className="col-sm-11 pr-3 pl-0">Reason for the test;</div>
            </div>
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(ii)</div>
              <div className="col-sm-11 pr-3 pl-0">Driver's name, date of birth, and CDL number and State of
                issuance;
              </div>
            </div>
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(iii)</div>
              <div className="col-sm-11 pr-3 pl-0">Employer name, address, and USDOT number;</div>
            </div>
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(iv)</div>
              <div className="col-sm-11 pr-3 pl-0">Date of the test;</div>
            </div>
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(v)</div>
              <div className="col-sm-11 pr-3 pl-0">Date the result was reported; and</div>
            </div>
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(vi)</div>
              <div className="col-sm-11 pr-3 pl-0">Test result. The test result must be one of the following:</div>
            </div>
            <div className="pl-4">
              <div className="pl-2 row">
                <div className="px-2" style={{width: '3em'}}>(A)</div>
                <div className="col-sm-11 pr-3 pl-0">Negative (only required for return-to-duty tests administered in
                  accordance with § 382.309);
                </div>
              </div>
              <div className="pl-2 row">
                <div className="px-2" style={{width: '3em'}}>(B)</div>
                <div className="col-sm-11 pr-3 pl-0"> Positive; or</div>
              </div>
              <div className="pl-2 row">
                <div className="px-2" style={{width: '3em'}}>(C)</div>
                <div className="col-sm-11 pr-3 pl-0">Refusal to take a test.(see directly below for additional
                  documentation requirements).
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        {(props.checklist.q3 || props.checklist.q4) &&
        <div className="pl-3 pb-2">
          <div className="pb-1 font-weight-bold">For each report of a <span
              style={{textDecoration: 'underline'}}>REFUSAL</span> violation of 49 CFR 40.261(a)(1) or
            40.191(a)(1), the employer must report the following information:
          </div>
          <div className="pl-1">
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(i)</div>
              <div className="col-sm-11 pr-3 pl-0">Documentation, including, but not limited to, electronic mail or
                other contemporaneous record of the time and date the driver was notified to appear at a testing site;
                and the time, date and testing site location at which the employee was directed to appear, or an
                affidavit providing evidence of such notification;
              </div>
            </div>
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(ii)</div>
              <div className="col-sm-11 pr-3 pl-0">Documentation, including, but not limited to, electronic mail or
                other correspondence, or an affidavit, indicating the date the employee was terminated or resigned (if
                applicable);
              </div>
            </div>
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(iii)</div>
              <div className="col-sm-11 pr-3 pl-0">Documentation, including, but not limited to, electronic mail or
                other correspondence, or an affidavit, showing that the C/TPA reporting the violation was designated
                as a service agent for an employer who employs himself/herself as a driver pursuant to paragraph (b)
                (6) (Owner-Operators) of this section when the reported refusal occurred (if applicable); and
              </div>
            </div>
            <div className="row pb-1 pl-2">
              <div className="px-2" style={{width: '3em'}}>(iv)</div>
              <div className="col-sm-11 pr-3 pl-0">Documentation, including a certificate of service or other evidence
                [i.e. mail, email, affidavit of discussion, memorandum], showing that the employer provided the
                employee with all documentation reported under paragraph (b)(3) (REFUSALS) of this section.
              </div>
            </div>
          </div>
        </div>
        }
        {props.checklist.q6 &&
        <div className="pl-3 pb-2">
          <div className="pl-1">
            <div className="pb-1 font-weight-bold">For each of the <span
                id={'actualKnowledge1'} style={{textDecoration: 'underline'}}>actual knowledge</span> violations as
              listed in the question above, the employer must report the following information:
            </div>
            <div className="pl-1">
              <div className="row pb-1 pl-2">
                <div className="px-2" style={{width: '3em'}}>(i)</div>
                <div className="col-sm-11 pr-3 pl-0">Driver's name, date of birth, CDL number and State of issuance
                </div>
              </div>
              <div className="row pb-1 pl-2">
                <div className="px-2" style={{width: '3em'}}>(ii)</div>
                <div className="col-sm-11 pr-3 pl-0">Employer name, address, and USDOT number, if applicable;</div>
              </div>
              <div className="row pb-1 pl-2">
                <div className="px-2" style={{width: '3em'}}>(iii)</div>
                <div className="col-sm-11 pr-3 pl-0">Date the employer obtained actual knowledge of
                  the violation;
                </div>
              </div>
              <div className="row pb-1 pl-2">
                <div className="px-2" style={{width: '3em'}}>(iv)</div>
                <div className="col-sm-11 pr-3 pl-0">Witnesses to the violation, if any, including contact
                  information;
                </div>
              </div>
              <div className="row pb-1 pl-2">
                <div className="px-2" style={{width: '3em'}}>(v)</div>
                <div className="col-sm-11 pr-3 pl-0">Description of the violation;
                </div>
              </div>
              <div className="row pb-1 pl-2">
                <div className="px-2" style={{width: '3em'}}>(vi)</div>
                <div className="col-sm-11 pr-3 pl-0">Evidence supporting each fact alleged in the description of the
                  violation required under paragraph (b)(4) of this section, which may include, but is not limited to,
                  affidavits, photographs, video or audio recordings, employee statements (other than admissions
                  pursuant to § 382.121), correspondence, or other documentation; and
                </div>
              </div>
              <div className="row pb-1 pl-2">
                <div className="px-2" style={{width: '3em'}}>(vii)</div>
                <div className="col-sm-11 pr-3 pl-0">A certificate of service or other evidence showing that the
                  employer provided the employee with all information reported under paragraph (b)(4) of this section.
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        {props.docType ? <MultiFileUploadContainer documentList={props.documentList}
                                                   docType={props.docType}
                                                   employeeId={props.employeeId}
                                                   onFileSelect={props.handleFileSelect}
                                                   fileList={props.fileList}
        /> : <div className={'font-weight-bold pt-3 text-danger'}>Client Not Configured for Drug and Alcohol Document
          Uploads</div>}
        {props.checklist.q6 &&
        <UncontrolledTooltip placement="right"
                             target={'actualKnowledge1'}
                             container='#root'
                             innerClassName={'tooltip-inner-wide'}
        >
          Actual knowledge for the purpose of subpart B of this part, means actual knowledge by an employer that a
          driver has used alcohol or controlled substances based on the employer's direct observation of the employee,
          information provided by the driver's previous employer(s), a traffic citation for driving a CMV while under
          the influence of alcohol or controlled substances or an employee's admission of alcohol or controlled
          substance use, except as provided in §382.121. Direct observation as used in this definition means
          observation
          of alcohol or controlled substances use and does not include observation of employee behavior or physical
          characteristics sufficient to warrant reasonable suspicion testing under §382.307. As used in this section,
          “traffic citation” means a ticket, complaint, or other document charging driving a CMV while under the
          influence of alcohol or controlled substances.
        </UncontrolledTooltip>}
      </Fragment>
  );
};

export default drugAlcoholUploadModalComponent;