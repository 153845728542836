import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {subscribeToTaskListChange} from "../../../utils/sockets/workflow";
import {cmeOperations} from "../../../ducks/cme";
import {dqInternalOperations} from "../../../ducks/dqInternal";
import {sessionOperations} from "../../../ducks/userSession";

import routes from '../../../routes';
import SidebarComponent from './sidebarComponent';
import permissions from '../../../config/permissions';
import openSocket from 'socket.io-client';

class SidebarContainer extends Component {

  componentDidMount() {
    //subscribe to socket
    if (window.env.ENABLE_SOCKET_IO === true && 
          (this.props.auth.user.permissions.includes(permissions.cme.searchAndMatch) 
          || this.props.auth.user.permissions.includes(permissions.cme.review) 
          || this.props.auth.user.permissions.includes(permissions.cme.secondReview) 
          || this.props.auth.user.permissions.includes(permissions.cme.finalReview))
    ) {
      const socket2 = openSocket.connect();
      this.props.setUserSocket(socket2);
      subscribeToTaskListChange(socket2, socket => this.props.setCMETaskListFromSocket(socket.taskList));
      this.props.getCMETaskList();
    }
    if (this.props.auth.user.permissions.includes(permissions.dq.worklists)) {
      this.props.getDQTaskList(this.props.auth.user, true);
    }
  }

  componentWillUnmount() {
    if (this.props.userSession.socket) {
      this.props.userSession.socket.disconnect();
    }
  }

  render() {
    if (!!this.props.auth.user.auditorToken) {
      return null;
    }
    let routeList = routes;
    if (this.props.auth.user.isConcordeUser) {
      routeList = routeList.filter((route) => !route.externalOnly);
    }
    return <SidebarComponent routes={routeList}/>
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  userSession: state.userSession
});

const mapDispatchToProps = {
  getCMETaskList: cmeOperations.getCMETaskList,
  setCMETaskListFromSocket: cmeOperations.setCMETaskListFromSocket,
  getDQTaskList: dqInternalOperations.getDQTaskList,
  setUserSocket: sessionOperations.setSocket
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarContainer));