import React, {Fragment} from 'react';
import Select from 'react-select';

const copySettingsModalComponent = props => {
  const targetSiteOptions = props.allowedTargetSites.map(site => ({value: site.legacyClientId, label: site.name}));
  targetSiteOptions.sort((a, b) => a.label.localeCompare(b.label));
  const {currentSite, selectedTargetSite} = props;
  return (
      <Fragment>
        <div className={'form-group row'}>
          <label className={'col-sm-3 col-form-label'}>Location to Add Settings to</label>
          <div className={'col-sm-9'}>
            <input
                className="form-control"
                placeholder={'Current Location'}
                value={currentSite.name}
                readOnly
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className={'col-sm-3 col-form-label'}>Location to Copy From</label>
          <div className={'col-sm-9'}>
            <Select
                classNamePrefix={'Select'}
                placeholder={'Source Location'}
                options={targetSiteOptions}
                value={selectedTargetSite ? targetSiteOptions.filter(option => option.value === selectedTargetSite.value)[0] : -1}
                onChange={option => props.handleSelect('selectedTargetSite', option)}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className={'col-sm-3 col-form-label'}>Settings to Copy</label>
          <ul className={'col-sm-9 list-group-flush'}>
            <li className="list-group-item form-check border-top-0 border-bottom-0">
              <input className="form-check-input"
                     type="checkbox"
                     id="docTypes"
                     name="docTypes"
                     onChange={props.handleCheckBoxChange}
                     checked={!!props.checkboxes.docTypes}
              />
              <label className="form-check-label" htmlFor="docTypes">File, Document Type and Job Title Setup</label>
              <div className={'small'}>(Employee document setup processing will continue in the background, after file
                setup changes complete.)
              </div>
            </li>
            <li className="list-group-item form-check border-top-0 border-bottom-0">
              <input className="form-check-input"
                     type="checkbox"
                     id="users"
                     name="users"
                     onChange={props.handleCheckBoxChange}
                     checked={!!props.checkboxes.users}
              />
              <label className="form-check-label" htmlFor="users">Users</label>
            </li>
            <li className="list-group-item form-check border-top-0 border-bottom-0">
              <input className="form-check-input"
                     type="checkbox"
                     id="notifications"
                     name="notifications"
                     onChange={props.handleCheckBoxChange}
                     checked={!!props.checkboxes.notifications}
              />
              <label className="form-check-label" htmlFor="notifications">Email notifications</label>
            </li>
            <li className="list-group-item form-check border-top-0 border-bottom-0">
              <input className="form-check-input"
                     type="checkbox"
                     id="medcardsubmissions"
                     name="medcardsubmissions"
                     onChange={props.handleCheckBoxChange}
                     checked={!!props.checkboxes.medcardsubmissions}
              />
              <label className="form-check-label" htmlFor="medcardsubmissions">Med Card submission</label>
            </li>
            <li className="list-group-item form-check border-top-0 border-bottom-0">
              <input className="form-check-input"
                     type="checkbox"
                     id="drivermonitoring"
                     name="drivermonitoring"
                     onChange={props.handleCheckBoxChange}
                     checked={!!props.checkboxes.drivermonitoring}
              />
              <label className="form-check-label" htmlFor="drivermonitoring">Driver monitoring</label>
            </li>

          </ul>
        </div>
      </Fragment>
  );
};

export default copySettingsModalComponent;