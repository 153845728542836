const formatSSN = (ssnString, redact = false) => {
  if(!ssnString){
    return null;
  }
  return ssnString.length === 9 ? (redact ? 'XXX' : ssnString.slice(0, 3)) + '-' + (redact ? 'XX' : ssnString.slice(3, 5)) + '-' + ssnString.slice(5) : ssnString;
};
const formatSSNOverride = (ssnString, redact = false, isHidden = true) => {
  let override = redact;
  if(!ssnString){
    return null;
  }
  if(isHidden){
    override = redact;
  } else {
    override = false;
  }
  return ssnString.length === 9 ? (override ? 'XXX' : ssnString.slice(0, 3)) + '-' + (override ? 'XX' : ssnString.slice(3, 5)) + '-' + ssnString.slice(5) : ssnString;
};
export default {formatSSN, formatSSNOverride};