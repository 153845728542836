import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom'
import _ from 'lodash';
import classNames from 'classnames';
import Ticker from 'react-ticker';
import AuthenticatedLinks from './components/authenticatedLinksComponent';
import CCRClientSelectorContainer from "../../Concorde/CCRClientSelector/CCRClientSelectorContainer";
import SitesSelector from "../../common/SimpleSitesSelector/SimpleSitesSelectorContainer";
import {authOperations} from '../../../ducks/auth';
import {layoutOperations} from "../../../ducks/layout";
import {dqfOperations} from "../../../ducks/dqf";
import permissions from '../../../config/permissions';
import {hasPermission} from '../../../utils/permissions'
import {concordeClient} from '../../../config/concordeClient';
import {UncontrolledTooltip} from 'reactstrap';
import routes from '../../../routes';

export class HeaderComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pauseTicker: false
    };
  }

  componentDidMount() {
    // ignore call for ccr on mount -- ccr needs to select their session client from the list 'component did update'
    if (this.props.auth.isAuthenticated && !hasPermission(this.props.auth.user, permissions.customerCare.assumeClient)) {
      this.props.getSiteTreeForClient(this.props.auth.user.client._id, this.props.auth.user && this.props.auth.user.permissions ? this.props.auth.user.permissions.includes(permissions.customerCare.assumeClient) : false);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.setSitesSelectorEnabled(false);
    }

    // non authenticated => authenticated
    if (this.props.auth.isAuthenticated && this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      this.props.getSiteTreeForClient(this.props.auth.user.client._id, this.props.auth.user && this.props.auth.user.permissions ? this.props.auth.user.permissions.includes(permissions.customerCare.assumeClient) : false);
    }

    // new client is selected in the CCR Client Selector
    //commenting this out because the set sites for user method is called and the sites and tree are now one call
    /*if (this.props.auth.isAuthenticated && hasPermission(this.props.auth.user, permissions.customerCare.assumeClient) && this.props.dqf.sites.sessionClient !== prevProps.dqf.sites.sessionClient) {
      this.props.getSiteTreeForClient(this.props.dqf.sites.sessionClient);
    }*/
  }

  handleLogoutClicked = () => {
    // NOTE -- do not do e.preventDefault() here. We want the logout link to go to the home page prior to logout so that
    // the next login will go to the home page (not the page the user was last on). Logout should wipe the slate clean :-).
    this.props.logoutUser();
  };

  handleToggleMenu = e => {
    e.preventDefault();
    this.props.setSidebar({isOpen: !(this.props.layout.sidebar.isOpen)});
  };

  // Recursive function to find the route that matches a path
  _findMatchingRoute = (routeTree, path) => {
    let currentRoute = null;
    let i = 0;
    while (!currentRoute && i < routeTree.length) {
      const route = routeTree[i];
      if (path === route.fullPath) {
        currentRoute = route;
      } else if (route.routes && route.routes.length > 0 && path.startsWith(route.fullPath)) {
        currentRoute = this._findMatchingRoute(route.routes, path);
      }
      i++;
    }
    return currentRoute;
  };

  handleCCRClientChanged = () => {
    this.props.setAlertCounts(null);
    // Look for an exact match of the path (the URL) in the list of all routes
    const currentRoute = this._findMatchingRoute(routes, this.props.location.pathname);
    // If there is no exact match, we are on a "detail" page (locaton, employee, etc.)
    // so we need to redirect to the home page because the client changed (otherwise,
    // we stay on the current page and it will update with the correct data).
    if (!currentRoute) {
      this.props.history.push('/');
    }
  };

  handleClickTicker = () => {
    this.setState({pauseTicker: !this.state.pauseTicker});
  };

  render() {
    const {isAuthenticated, user} = this.props.auth;
    const isAuditor = !!user.auditorToken;
    const showHideIndicator = this.props.layout.sidebar.isOpen ? 'hide' : 'show';
    return (
        <header id="header" className={classNames({'impersonating': this.props.auth.impersonatingUserId})}>
          <div className="d-flex justify-content-between">
            <div className={'hamburger-and-logo-container'}>
              <div className={'logo-container'}>
                <Link to="/">
                  <img src="/images/Concorde_SPARC_logo.png"
                       className="concorde-logo"
                       height={40}
                       alt="Concorde SPARC logo"/>
                </Link>
              </div>
              {isAuthenticated && !isAuditor &&
              <div className="toggle-nav"
                   onClick={this.handleToggleMenu}>
                <i className="fas fa-bars" id={'hamburger'}/>
                <UncontrolledTooltip placement="bottom" target={'hamburger'} container={'#root'}>
                  Click to {showHideIndicator} menu
                </UncontrolledTooltip>
              </div>
              }
            </div>
            {isAuthenticated && this.props.layout.broadcast && this.props.layout.broadcast.length > 0 &&
            <div className={'flex-fill align-self-center px-2'} onClick={this.handleClickTicker}
                 style={{cursor: this.state.pauseTicker ? 'default' : 'grab'}}>
              <Ticker offset={'50%'} speed={5} move={!this.state.pauseTicker}>
                {({index}) => <div className={'mr-5'}>
                  {this.props.layout.broadcast[index % this.props.layout.broadcast.length]}
                </div>}
              </Ticker>
            </div>
            }
            <div className="header-selected-sites pt-1">
              {isAuthenticated &&
              <div className="d-flex justify-content-between">
                {hasPermission(this.props.auth.user, permissions.customerCare.assumeClient) &&
                <div className="mr-1" style={{minWidth: 300}}>
                  <CCRClientSelectorContainer
                      disabled={this.props.layout.loadingPanel.instances > 0}
                      onClientChanged={this.handleCCRClientChanged}
                  />
                </div>
                }
                {(this.props.dqf.sites.sitesSelectorEnabled || !!this.props.auth.user.auditorToken) &&
                <div
                    className={classNames({'d-none': !!this.props.auth.user.auditorToken})}
                    style={{minWidth: 300}}
                >
                  <SitesSelector
                      selectIsDisabled={this.props.layout.loadingPanel.instances > 0}
                      autoSelectSite={this.props.dqf.sites.autoSelectSite}
                      displaySites={_.orderBy(this.props.auth.user.sites, ['name'], ['asc']).filter(s => s.legacyClientId !== concordeClient.legacyClientId)} // including concorde client makes things more confusing than they need to be;
                  />
                </div>
                }
              </div>
              }
            </div>
            {isAuthenticated &&
            <div>
              <AuthenticatedLinks onLogoutClicked={this.handleLogoutClicked} isAuditor={isAuditor}/>
            </div>
            }
          </div>
        </header>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  dqf: state.dqf,
  layout: state.layout
});

const mapDispatchToProps = {
  logoutUser: authOperations.logoutUser,
  setSidebar: layoutOperations.setSidebar,
  getSiteTreeForClient: authOperations.getSitesForUser,
  setSitesSelectorEnabled: dqfOperations.setSitesSelectorEnabled,
  setAlertCounts: dqfOperations.setAlertCounts
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent));