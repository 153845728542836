import types from './types';

const setCMESearchQuery = query => ({
  type: types.SET_CME_SEARCH_QUERY,
  payload: query
});

const resetCMESearch = () => ({
  type: types.RESET_CME_SEARCH,
  payload: null
});

const setCMESearchResults = (searchResults, status = 200) => ({
  type: types.SET_CME_SEARCH_RESULTS,
  payload: {searchResults, status}
});

const setCMESearchFetching = fetching => ({
  type: types.SET_CME_FETCHING_RESULTS,
  payload: fetching
});

const setCMETaskList = taskList => ({
  type: types.SET_CME_TASKLIST,
  payload: taskList
});

const setCustomerCareInterventionItems = items => ({
  type: types.SET_CUSTOMER_CARE_INTERVENTION_ITEMS,
  payload: items
});

export default {
  setCMESearchQuery,
  setCMESearchResults,
  setCMESearchFetching,
  setCMETaskList,
  resetCMESearch,
  setCustomerCareInterventionItems
};