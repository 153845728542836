import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import axios from "axios";
import {modalOperations} from "../../../../../../../../../ducks/modal/index";
import DOTNumberModal from './dotNumberModalComponent'
import {alertsOperations} from "../../../../../../../../../ducks/alerts/index";
import _ from "lodash";
import {Rnd} from 'react-rnd';

class DOTNumberModalContainer extends Component {
  constructor(props) {
    super(props);

    //the props are coming in on the info prop of the modal container, so map them to the ones we need
    this.state = {};
  }

  componentDidMount() {
    this.showDOTModal();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.info.selectedClientId !== prevProps.info.selectedClientId) {
      this.showDOTModal();
    }
  }

  showDOTModal = () => {
    axios.get(`/v1/clients/${this.props.info.selectedClientId}/children?source=legacy&includeParent=true&forUser=${this.props.auth.user._id}`)
    .then(response => {
      const dotListWithDupes = response.data.filter((item) => {
        return item.DOTNumber && item.DOTNumber !== ''
      }).map((item) => {
        return {value: item.DOTNumber, label: item.DOTNumber}
      });
      const dotList = _.uniqBy(dotListWithDupes, (item) => {
        return item.value.trim();
      });
      if (dotList && dotList.length === 1) {
        this.props.hideModal(false, [dotList[0].value]); //if there is only one location, dont' show the modal
      } else if (dotList && dotList.length === 0) {
        this.props.addAlert('There are no DOT numbers associated with this location.', 'danger');
        this.props.hideModal(false, []);
      } else {
        this.setState({dotList: dotList});
      }
    })
    .catch(err => {
      console.error(err);
    });
  };

  selectNumbers = () => {
    if (this.state.selectedItems && this.state.selectedItems.length > 0) {
      this.props.hideModal(false, this.state.selectedItems.map((item) => item.value));
    } else {
      this.props.hideModal(false, []);
    }

  };

  handleDOTListChange = (options) => {
    this.setState({selectedItems: options});
  };

  render() {

    const {dotList} = this.state;
    if (!dotList) {
      return null;
    }
    return (
        <Fragment>
          <Modal isOpen={true}>
            <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
              <ModalHeader className={'cursor-move'}>Select DOT Number(s) to run report for</ModalHeader>
              <ModalBody>
                <DOTNumberModal dotList={dotList} onDOTListChange={this.handleDOTListChange}
                />
              </ModalBody>
              <ModalFooter>
                <button className={'btn btn-primary btn-sm'} id='copy' onClick={this.selectNumbers}>OK
                </button>
              </ModalFooter>
            </Rnd>
          </Modal>
        </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  addAlert: alertsOperations.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(DOTNumberModalContainer);