import axios from 'axios';

export const apiService = (params = {}) => {
  const source = axios.CancelToken.source();

  const promise = axios.request({ ...params, cancelToken: source.token });

  const cancel = () => source.cancel();

  return { promise, cancel };
};
