import React from 'react';
import moment from 'moment';
import SocialSecurityNumberDisplayContainer
  from "../../../common/SocialSecurityNumber/SocialSecurityNumberDisplay/SocialSecurityNumberDisplayContainer";

const cmeResearchEmployeeInfoComponent = props => {

  return (
      <div className={'row pl-3'}>
        <div className={'font-weight-light col-sm-2 d-flex align-items-center'}>
          <div className={'row'}>
            <div>Employee Id:</div>
            <span className={'font-weight-bold pl-1'}>
            {props.employeeInfo.employeeId}
          </span>
          </div>
        </div>
        <div className={'font-weight-light col-sm-2 d-flex align-items-center'}>
          <div className={'row'}>
            <div>Name:</div>
            <span className={'font-weight-bold pl-1'}>
            {props.employeeInfo.firstName} {props.employeeInfo.lastName}
          </span>
          </div>
        </div>
        <div className={'font-weight-light col-sm-2 d-flex align-items-center'}>
          <div className={'row'}>
            <div>SSN:</div>
            <span className={'font-weight-bold pl-1'}>
                <SocialSecurityNumberDisplayContainer ssn={props.employeeInfo.ssn} redact={false}/>
          </span>
          </div>
        </div>
        <div className={'font-weight-light col-sm-2 d-flex align-items-center'}>
          <div className={'row'}>
            <div>Work Id:</div>
            <span className={'font-weight-bold pl-1'}>
                {props.employeeInfo.workId}
          </span>
          </div>
        </div>
        <div className={'font-weight-light col-sm-2 d-flex align-items-center'}>
          <div className={'row'}>
            <div>Date of Birth:</div>
            <span className={'font-weight-bold pl-1'}>
            {props.employeeInfo.dob ? moment(props.employeeInfo.dob).utc().format('M/D/YYYY') : ''}
          </span>
          </div>
        </div>
        <div className={'font-weight-light col-sm-2'}>
          <button type="button"
                  className="btn btn-sm btn-primary"
                  onClick={props.onShowDocuments}
          >
            {props.showDocuments ? 'Show Orders' : 'Show Documents'}
          </button>
        </div>
      </div>
  );
};

export default cmeResearchEmployeeInfoComponent;