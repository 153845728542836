import types from './types';
import step3DefaultState from './defaultState';

const randomsPoolCreationWizardStep2Reducer = (state = step3DefaultState, action) => {
  switch (action.type) {
    // =======================================================================================
    //                             Randoms Pool Creation Wizard - Step 3
    // =======================================================================================
    case types.RESET_STEP_3_GLOBAL_STATE: 
      return step3DefaultState

    case types.SET_STEP3_DEFAULT_RANDOMS_TESTING_RATES: 
      const {
        testingAuthoritiesAndRates,
        testingAuthoritiesList,
        testingAuthoritySelected,
        percentageDrug,
        percentageAlcohol,
        poolName
      } = action.payload;

      return {
        ...state,
        testingAuthoritiesAndRates,
        testingAuthoritiesList,
        testingAuthoritySelected,
        percentageDrug,
        percentageAlcohol,
        poolName
      }

    case types.SET_STEP3_EXISTING_POOL_NAMES: 
      return {
        ...state,
        existingPoolNames: action.payload
      }

    case types.SET_STEP3_FOR_EDITING: 
      return {
        ...state,
        ...action.payload
      }

    case types.SET_STEP3_FREQUENCY_SELECTION: 
      return {
        ...state,
        selectionFrequencySelected: action.payload
      }

    case types.SET_STEP3_PERCENTAGE_ALCOHOL: 
      return {
        ...state,
        percentageAlcohol: action.payload
      }

    case types.SET_STEP3_PERCENTAGE_DRUG: 
      return {
        ...state,
        percentageDrug: action.payload
      }

    case types.SET_STEP3_POOL_NAME: 
      return {
        ...state,
        poolName: action.payload
      }

    case types.SET_STEP3_POOL_NAME_ALREADY_EXISTS: 
      return {
        ...state,
        poolNameAlreadyExists: action.payload
      }

    case types.SET_STEP3_TESTING_AUTHORITY_SELECTION: 
      return {
        ...state,
        testingAuthoritySelected: action.payload,
        // the values below serve as both the default values and the minimum values for the drug % and alcohol % inputs
        percentageDrug: action.payload.drug,
        percentageAlcohol: action.payload.alcohol
      }

    default:
      return state;
  }
}

export default randomsPoolCreationWizardStep2Reducer;
