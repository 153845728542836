import React, {Fragment} from 'react';
import moment from "moment";
import ReactTable from 'react-table';
import PageMessage from '../../../common/PageMessage/pageMessageComponent';

const cmeResearchAuditTrailComponent = props => {

  const tableColumns = [
    {
      id: 'colReportTitle',
      Header: (<span className={'h6 mt-1 mb-0'}>{'Audit Trail'}</span>),
      columns: [
        {
          id: 'colDate',
          Header: 'Date',
          Cell: ({value}) => value ? moment(value).format('M/D/YYYY h:mm a') : '',
          accessor: 'AuditTime',
          headerClassName: 'text-left',
          width: 170
        },
        {
          id: 'colUserName',
          Header: 'UserName',
          accessor: 'UserName',
          headerClassName: 'text-left',
          width: 180
        },
        {
          id: 'colMessage',
          Header: 'Message',
          accessor: 'Message',
          headerClassName: 'text-left'
        }
      ]
    }];

  const maxRows = 20;
  const dataLength = props.data ? props.data.length : 0;
  let defaultPageSize = dataLength <= maxRows ? dataLength : maxRows;
  if (defaultPageSize === 0) {
    defaultPageSize = 3;
  }

  return (
      <Fragment>
        <ReactTable
            key={defaultPageSize}
            className={'-striped -highlight -cursor-pointer'}
            columns={tableColumns}
            data={props.data}
            defaultPageSize={defaultPageSize}
            showPagination={dataLength > defaultPageSize}
            noDataText={
              <PageMessage color={'white'} bgColor={'info'} text={'No audit trail entries found'}/>
            }
        />
      </Fragment>
  )
};

export default cmeResearchAuditTrailComponent;