import React from 'react';
import Select from 'react-select';

const transferDQFileComponent = props => {
  const targetSiteOptions = props.allowedTargetSites.map(site => ({value: site.legacyClientId, label: site.name}));
  targetSiteOptions.sort((a, b) => a.label.localeCompare(b.label));
  const jobTitleOptions = props.jobTitles.map(jobTitle => ({value: jobTitle.jobTitleId, label: jobTitle.jobTitle}));
  jobTitleOptions.sort((a, b) => a.label.localeCompare(b.label));
  const employmentStatusOptions = props.employmentStatuses.map(status => ({
    value: status.employmentStatusId,
    label: status.status
  }));
  employmentStatusOptions.sort((a, b) => a.label.localeCompare(b.label));
  const {currentSite} = props;
  return (
      <>
        <div className={'form-group row'}>
          <label className={'col-sm-3 col-form-label'}>Current Location</label>
          <div className={'col-sm-9'}>
            <input
                className="form-control"
                placeholder={'Current Location'}
                value={currentSite}
                readOnly
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className={'col-sm-3 col-form-label'}>New Location</label>
          <div className={'col-sm-9'}>
            <Select
                classNamePrefix={'Select'}
                placeholder={'New Location'}
                options={targetSiteOptions}
                value={targetSiteOptions.filter(option => option.value === props.selectedSite.value)[0]}
                onChange={option => props.handleSelect('selectedTargetSite', option)}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className={'col-sm-3 col-form-label'}>Job Title</label>
          <div className={'col-sm-9'}>
            <Select
                classNamePrefix={'Select'}
                placeholder={'Job Title'}
                options={jobTitleOptions}
                value={jobTitleOptions.filter(option => option.value === props.selectedJobTitle.value)[0]}
                onChange={option => props.handleSelect('selectedJobTitle', option)}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className={'col-sm-3 col-form-label'}>Status</label>
          <div className={'col-sm-9'}>
            <Select
                classNamePrefix={'Select'}
                placeholder={'Status'}
                options={employmentStatusOptions}
                value={employmentStatusOptions.filter(option => option.value === props.selectedEmploymentStatus.value)[0]}
                onChange={option => props.handleSelect('selectedEmploymentStatus', option)}
            />
          </div>
        </div>
      </>
  );
};

export default transferDQFileComponent;