const SET_CME_SEARCH_QUERY = 'SET_CME_SEARCH_QUERY';
const SET_CME_SEARCH_RESULTS = 'SET_CME_SEARCH_RESULTS';
const SET_CME_TASKLIST = 'SET_CME_TASKLIST';
const SET_CME_FETCHING_RESULTS = 'SET_CME_FETCHING_RESULTS';
const RESET_CME_SEARCH =  'RESET_CME_SEARCH';
const SET_CUSTOMER_CARE_INTERVENTION_ITEMS = 'SET_CUSTOMER_CARE_INTERVENTION_ITEMS';

export default {
  SET_CME_SEARCH_QUERY,
  SET_CME_SEARCH_RESULTS,
  SET_CME_TASKLIST,
  SET_CME_FETCHING_RESULTS,
  RESET_CME_SEARCH,
  SET_CUSTOMER_CARE_INTERVENTION_ITEMS
};