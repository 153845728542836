import React, {Fragment} from 'react';
import classNames from 'classnames';
import MenuEntry from '../MenuEntry/MenuEntryContainer';

const menuHeadingComponent = ({path, text, components, routes, expanded, needClient, onClick}) => {
  return (
      <Fragment>
        {!!text &&
        <div className={classNames({'menu-heading': true, 'expanded': expanded, 'disabled': needClient})} onClick={onClick}>
          {text}
        </div>
        }
        {(!text || expanded) &&
        <Fragment>
          {components}
          {routes.map((route, index) => {
            return (
                <MenuEntry
                    key={index}
                    path={path}
                    entry={route}
                    indent={!!text}
                />
            );
          })}
        </Fragment>
        }
      </Fragment>
  );
};

export default menuHeadingComponent;