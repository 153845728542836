import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import jwt_decode from 'jwt-decode';

import store from './store';
import setAuthToken from './utils/setAuthToken';
import {getCookie, setCookie} from './utils/storageUtils';
import {authOperations} from './ducks/auth/index';
import {sessionOperations} from "./ducks/userSession";
import {alertsOperations} from './ducks/alerts';
import {layoutOperations} from './ducks/layout';

import App from './App';
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-sortable-tree/style.css';

//the service worker was causing issues with requiring users to clear their cookies before logging in
//import registerServiceWorker from './registerServiceWorker';
import axios from "axios";

const jwtToken = getCookie('jwtToken');
if (jwtToken && jwtToken.length > 0) { // exists and not an empty string
  setAuthToken(jwtToken);
  const decoded = jwt_decode(jwtToken);
  store.dispatch(authOperations.getFullCurrentUser(decoded._id));
  store.dispatch(sessionOperations.setSessionStatus('ACTIVE'));
}

/***** Start of code for intercepting axios calls, detecting 401 errors, refreshing the bearer token, and retrying *****/

// If multiple requests fail due to bearer token expiration, we don't want to generate multiple server request
// to refresh the token. So, use a flag and keep a list of the requests that need to be re-submitted once
// the token has been refreshed.
let isAlreadyRefreshingToken = false;
let subscribersToRetry = [];

// Add a retry subscriber. The callback() will accept the new token and insert it into the original request
// before re-submitting the request to the server.
const addRetrySubscriber = callback => {
  subscribersToRetry.push(callback);
};

// When the bearer token is refreshed after a server error,  loop through all subscribers and reset the subscriber list
const retryAllSubscribersWithNewToken = token => {
  // console.log(subscribersToRetry.length, 'subscribers to retry');
  subscribersToRetry = subscribersToRetry.filter(callback => callback(token));
  // console.log(subscribersToRetry.length, 'subscribers remain');
};

// Intercept all responses so that we can check for a 401 (unauthorized) error. this is safer than checking the
// token expiration in a request interceptor, because that approach could lead to a "race" condition (we think the
// token is still valid, but after checking it, it actually expires before the server receives it)
axios
.interceptors
.response
.use(
    response => {
      if (response.config.headers.Authorization && !response.headers['no-auth-refresh']) {
        //console.log('session status active: ' + response.config.url);
        store.dispatch(sessionOperations.setSessionStatus('ACTIVE'));
      }
      return response;
    },
    error => {
      const {config, response, message} = error;
      const originalRequest = config;
      let status, data;
      if (response) { // Sometimes there's no response object; double-destructuring doesn't always work!
        status = response.status;
        data = response.data;
      }
      if ((!response && !axios.isCancel(error) && message !== 'Network Error') || (status === 401 && data === 'Unauthorized')) {
        // Since the request failed and needs to be retried, we have to return a new Promise. The Promise
        // will resolve after we resubmit the request (which will happen after we get the new token)
        const retryPromise = new Promise((resolve, reject) => {
          // Push an asynchronous function onto the retry list; it will accept the new token, stuff it into
          // the original API request, and retry the original request with the new token.
          addRetrySubscriber(token => {
            originalRequest.headers.Authorization = token;
            axios(originalRequest)
            .then(resolve)
            .catch(reject);
          });
        });
        // If multiple requests failed (e.g., from components on the same page), it's only necessary to issue ONE
        // request for a new token.
        const refreshToken = getCookie('refreshToken');
        if (response) {
          console.log('in error url: ' + response.config.url + ' isalreadyrefreshing: ' + isAlreadyRefreshingToken.toString());
        } else {
          console.log('in error no response, isalreadyrefreshing: ' + isAlreadyRefreshingToken.toString());
        }

        if (!isAlreadyRefreshingToken && !!refreshToken) {
          isAlreadyRefreshingToken = true;
          axios.get('/v1/auth/token', {headers: {Authorization: refreshToken}})
          .then(({data}) => {
            setCookie('jwtToken', data.token);
            setCookie('refreshToken', data.refreshToken);
            setAuthToken(data.token);
            // WE PROBABLY DON'T NEED THE FOLLOWING TWO LINES . . .
            // const decoded = jwt_decode(data.token);
            // store.dispatch(authOperations.getFullCurrentUser(decoded._id, true));
            isAlreadyRefreshingToken = false;
            // Now that we've got the new token, retry all of the subscribers (the requests that failed)
            retryAllSubscribersWithNewToken(data.token);
          })
          .catch(err => {
            isAlreadyRefreshingToken = false;
            console.error('Error generating refresh token!', err);
            store.dispatch(alertsOperations.addAlert('We apologize but we are unable to proceed due to a user authentication error - try logging out and back in', 'danger'));
            store.dispatch(layoutOperations.resetLoadingPanel());
            return Promise.reject(error);
          });
        }
        // Return the Promise that we created above
        return retryPromise;
      }

      // If the API request failed for any reason other than 401 (unauthorized), reject the Promise and return the error.
      return Promise.reject(error);
    }
);

/***** End of code for intercepting axios calls, detecting 401 errors, refreshing the bearer token, and retrying *****/

ReactDOM.render((
    <Provider store={store}>
      <App/>
    </Provider>
), document.getElementById('root'));
//the service worker was causing issues with requiring users to clear their cookies before logging in
//registerServiceWorker();