import types from './types';

const setClientList = clientList => {
  return ({
    type: types.SET_CLIENT_LIST,
    payload: clientList
  });
};

export default {
  setClientList
};