import React from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';

const breadcrumbsComponent = (props) => {
  const {route} = props;
  // Don't render a breadcrumb for the Home page
  if (route.path === '/' || !props.userAuthenticated) {
    return null;
  }

  return (
      <Breadcrumb>
        <BreadcrumbItem><Link to={'/'}>Home</Link></BreadcrumbItem>
        {route.breadcrumbs.map((breadcrumb, breadcrumbIndex) => {
          if (breadcrumbIndex === route.breadcrumbs.length - 1) {
            // If it's the "tail" of the breadcrumbs, render it as the active one
            return (
                <BreadcrumbItem active key={`${breadcrumb.url}_${breadcrumb.text}`}>
                  {breadcrumb.text}
                </BreadcrumbItem>
            );
          } else if (!!breadcrumb.text) {
            // Prior to the "tail" render each breadcrumb with a link (but only if it's not empty;
            // if empty, it's an invisible menu header used to group children under a path)
            return (
                <BreadcrumbItem key={`${breadcrumb.url}_${breadcrumb.text}`}>
                  <Link to={breadcrumb.url}>
                    {breadcrumb.text}
                  </Link>
                </BreadcrumbItem>
            );
          } else {
            return '';
          }
        })}
      </Breadcrumb>
  );
};

export default breadcrumbsComponent;