import types from './types';

const setPathMapping = (path, url, text) => ({
  type: types.SET_PATH_MAPPING,
  payload: {
    path,
    url,
    text
  }
});

const deletePathMapping = path => ({
  type: types.DELETE_PATH_MAPPING,
  payload: path
});

export default {
  setPathMapping,
  deletePathMapping
};