const RESET_STEP_2_GLOBAL_STATE = 'RESET_STEP_2_GLOBAL_STATE';
// Employment Status
const SET_STEP2_BY_EMPLOYMENT_STATUS_SELECTION_VALUES = 'SET_STEP2_BY_EMPLOYMENT_STATUS_SELECTION_VALUES';
const SET_STEP2_EMPLOYMENT_STATUS_CHECKBOX = 'SET_STEP2_EMPLOYMENT_STATUS_CHECKBOX';
const SET_STEP2_EMPLOYMENT_STATUS_VALUES_FOR_DROPDOWN = 'SET_STEP2_EMPLOYMENT_STATUS_VALUES_FOR_DROPDOWN';
const SET_STEP2_FOR_EDITING = 'SET_STEP2_FOR_EDITING';

// Job Titles
const SET_STEP2_BY_JOB_TITLE_SELECTION_VALUES = 'SET_STEP2_BY_JOB_TITLE_SELECTION_VALUES';
const SET_STEP2_JOB_TITLE_CHECKBOX = 'SET_STEP2_JOB_TITLE_CHECKBOX';
const SET_STEP2_JOB_TITLE_VALUES_FOR_DROPDOWN = 'SET_STEP2_JOB_TITLE_VALUES_FOR_DROPDOWN';
// Safety Codes
const SET_STEP2_BY_SAFETY_CODE_SELECTION_VALUES = 'SET_STEP2_BY_SAFETY_CODE_SELECTION_VALUES';
const SET_STEP2_SAFETY_CODE_CHECKBOX = 'SET_STEP2_SAFETY_CODE_CHECKBOX';

export default {
  RESET_STEP_2_GLOBAL_STATE,
  SET_STEP2_BY_EMPLOYMENT_STATUS_SELECTION_VALUES,
  SET_STEP2_EMPLOYMENT_STATUS_CHECKBOX,
  SET_STEP2_EMPLOYMENT_STATUS_VALUES_FOR_DROPDOWN,
  SET_STEP2_FOR_EDITING,
  SET_STEP2_BY_JOB_TITLE_SELECTION_VALUES,
  SET_STEP2_JOB_TITLE_CHECKBOX,
  SET_STEP2_JOB_TITLE_VALUES_FOR_DROPDOWN,
  SET_STEP2_BY_SAFETY_CODE_SELECTION_VALUES,
  SET_STEP2_SAFETY_CODE_CHECKBOX
}