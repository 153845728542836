const SET_SIDEBAR = 'SET_SIDEBAR';

const LAYOUT_SHOW_LOADING_PANEL = 'LAYOUT_SHOW_LOADING_PANEL';
const LAYOUT_HIDE_LOADING_PANEL = 'LAYOUT_HIDE_LOADING_PANEL';
const LAYOUT_RESET_LOADING_PANEL = 'LAYOUT_RESET_LOADING_PANEL';

const USER_OPTIONS_ADD = 'USER_OPTIONS_ADD';
const USER_OPTIONS_CLEAR = 'USER_OPTIONS_CLEAR';
const SET_SSNMASK = 'SET_SSNMASK';

const SET_BROADCAST = 'SET_BROADCAST';


export default {
  SET_SIDEBAR,
  LAYOUT_SHOW_LOADING_PANEL,
  LAYOUT_HIDE_LOADING_PANEL,
  LAYOUT_RESET_LOADING_PANEL,
  USER_OPTIONS_ADD,
  USER_OPTIONS_CLEAR,
  SET_SSNMASK,
  SET_BROADCAST
};