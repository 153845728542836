const DQF_SET_ROSTER_VIEW = 'DQF_SET_ROSTER_VIEW';
const DQF_SET_RANDOMS_ROSTER_VIEW = 'DQF_SET_RANDOMS_ROSTER_VIEW';
const DQF_SET_RANDOMS_POOL_ROSTER_VIEW = 'DQF_SET_RANDOMS_POOL_ROSTER_VIEW';
const DQF_SET_SORTED_FILTERED_ROSTER = 'DQF_SET_SORTED_FILTERED_ROSTER';
const DQF_SET_ROSTER_SELECTED_LOCATION = 'DQF_SET_ROSTER_SELECTED_LOCATION';

const DQF_SEARCH_EMPLOYEES = 'DQF_SEARCH_EMPLOYEES';

const DQF_NOTIFICATIONS_SET_LOADING = 'DQF_NOTIFICATIONS_SET_LOADING';
const DQF_NOTIFICATIONS_SET_LIST = 'DQF_NOTIFICATIONS_SET_LIST';
const DQF_NOTIFICATIONS_CHANGED = 'DQF_NOTIFICATIONS_CHANGED';
const DQF_NOTIFICATIONS_SAVE_CHANGES = 'DQF_NOTIFICATIONS_SAVE_CHANGES';
const DQF_NOTIFICATIONS_SET_EDITING = 'DQF_NOTIFICATIONS_SET_EDITING';
const DQF_NOTIFICATIONS_CANCEL_CHANGES = 'DQF_NOTIFICATIONS_CANCEL_CHANGES';
const DQF_NOTIFICATIONS_SET_FILTER_BY = 'DQF_NOTIFICATIONS_SET_FILTER_BY';
const DQF_NOTIFICATIONS_APPLY_CHANGES = 'DQF_NOTIFICATIONS_APPLY_CHANGES';
const DQF_SET_FILTERED_EXCEL_DATA = 'DQF_SET_FILTERED_EXCEL_DATA';

const DQF_SITES_SET_SELECTED = 'DQF_SITES_SET_SELECTED';
const DQF_SITES_SET_SITE_TREE = 'DQF_SITES_SET_SITE_TREE';
const DQF_SITES_SET_SESSION_CLIENT = 'DQF_SITES_SET_SESSION_CLIENT';
const DQF_SET_SELECTED_HIERARCHY_SITES = 'DQF_SET_SELECTED_HIERARCHY_SITES';
const DQF_SET_AUTO_SELECT_SITE = 'DQF_SET_AUTO_SELECT_SITE';
const DQF_SET_SITES_SELECTOR_ENABLED = 'DQF_SET_SITES_SELECTOR_ENABLED';

const DQF_SET_ALERT_COUNTS = 'DQF_SET_ALERT_COUNTS';
const DQF_SET_ALERTS_VALUES = 'DQF_SET_ALERTS_VALUES';

const DQF_SET_DISPLAY_INCLUDE_CHILDREN = 'DQF_SET_DISPLAY_INCLUDE_CHILDREN';

const DQF_SET_DOCUMENT_TABS_SINGLE_OR_MULTI = 'DQF_SET_DOCUMENT_TABS_SINGLE_OR_MULTI';

const DQF_SET_UNSCORED_MVRS = 'DQF_SET_UNSCORED_MVRS';

export default {
  DQF_SET_ROSTER_VIEW,
  DQF_SET_RANDOMS_ROSTER_VIEW,
  DQF_SET_RANDOMS_POOL_ROSTER_VIEW,
  DQF_SET_SORTED_FILTERED_ROSTER,
  DQF_SET_ROSTER_SELECTED_LOCATION,
  DQF_SEARCH_EMPLOYEES,
  DQF_NOTIFICATIONS_SET_LOADING,
  DQF_NOTIFICATIONS_SET_LIST,
  DQF_NOTIFICATIONS_CHANGED,
  DQF_NOTIFICATIONS_SAVE_CHANGES,
  DQF_NOTIFICATIONS_SET_EDITING,
  DQF_NOTIFICATIONS_CANCEL_CHANGES,
  DQF_NOTIFICATIONS_SET_FILTER_BY,
  DQF_NOTIFICATIONS_APPLY_CHANGES,
  DQF_SITES_SET_SELECTED,
  DQF_SITES_SET_SITE_TREE,
  DQF_SITES_SET_SESSION_CLIENT,
  DQF_SET_SELECTED_HIERARCHY_SITES,
  DQF_SET_AUTO_SELECT_SITE,
  DQF_SET_SITES_SELECTOR_ENABLED,
  DQF_SET_ALERT_COUNTS,
  DQF_SET_FILTERED_EXCEL_DATA,
  DQF_SET_ALERTS_VALUES,
  DQF_SET_DISPLAY_INCLUDE_CHILDREN,
  DQF_SET_DOCUMENT_TABS_SINGLE_OR_MULTI,
  DQF_SET_UNSCORED_MVRS
};