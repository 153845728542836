import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import {alertsOperations} from '../../../ducks/alerts';
import {connect} from 'react-redux';

/**
 * must have either id or key.
 */
class DateInputWrapperContainer extends Component {

  handleDatePickerChange = date => {
    if (date && (!date.isValid() || date.isBefore('1900-01-01') || !date.isBefore('2100-01-01'))) {
      this.props.addAlert(`The date that you entered (${date ? date.format('M/D/YYYY') : ''}) is invalid. Please enter a valid date between 1900 and 2099`, 'danger');
      this.props.onChangeHandler(null);
    } else {
      this.props.onChangeHandler(date);
    }
  };

  render() {
    const other = _.omit(this.props, ['keyName', 'id', 'onChangeHandler']);
    return (
        <DatePicker
            key={this.props.keyName || this.props.id}
            id={this.props.id || this.props.keyName}
            {...other}
            onChange={this.handleDatePickerChange}
        />
    );
  }

}

const mapDispatchToProps = {
  addAlert: alertsOperations.addAlert
};

export default connect(null, mapDispatchToProps)(DateInputWrapperContainer);