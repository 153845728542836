// Step 1
const SET_STEP1_BY_DOT_NUMBER_SELECTION_VALUES = 'SET_STEP1_BY_DOT_NUMBER_SELECTION_VALUES';
const SET_STEP1_BY_LOCATION_NAME_SELECTION_VALUES = 'SET_STEP1_BY_LOCATION_NAME_SELECTION_VALUES';
const RESET_STEP_1_GLOBAL_STATE = 'RESET_STEP_1_GLOBAL_STATE';
const SET_STEP1_DOT_LIST_FOR_DROPDOWN = 'SET_STEP1_DOT_LIST_FOR_DROPDOWN';
const SET_STEP1_FOR_EDITING = 'SET_STEP1_FOR_EDITING';
const SET_STEP1_LOCATION_SEARCH_QUERY = 'SET_STEP1_LOCATION_SEARCH_QUERY';
const SET_STEP1_SELECTION = 'SET_STEP1_SELECTION';

export default {
  SET_STEP1_BY_DOT_NUMBER_SELECTION_VALUES,
  SET_STEP1_BY_LOCATION_NAME_SELECTION_VALUES,
  RESET_STEP_1_GLOBAL_STATE,
  SET_STEP1_DOT_LIST_FOR_DROPDOWN,
  SET_STEP1_FOR_EDITING,
  SET_STEP1_LOCATION_SEARCH_QUERY,
  SET_STEP1_SELECTION,
}