import types from './types';

const initialState = {
  pathMap: {}
};

const breadcrumbReducer = (state = initialState, action) => {
  let pathMap;
  switch (action.type) {
      // Add a path mapping that translates a path (route) into a URL and text (for the breadcrumb)
    case types.SET_PATH_MAPPING:
      pathMap = state.pathMap;
      pathMap[action.payload.path] = {
        url: action.payload.url,
        text: action.payload.text
      };
      return {
        ...state,
        pathMap
      };
    case types.DELETE_PATH_MAPPING:
      pathMap = state.pathMap;
      delete pathMap[action.payload];
      return {
        ...state,
        pathMap
      };
    default:
      return state;
  }
};

export default breadcrumbReducer;