import React, {PureComponent} from 'react';
import Select from 'react-select';
import _ from 'lodash';
import states from '../../../config/statesAndProvinces.json';

export default class StateSelect extends PureComponent {
  render() {

    const displayMode = this.props.displayMode || 'fancy';
    if (displayMode.toLowerCase() === 'fancy') {
      const stateOptions = _.sortBy(states, ['abbreviation']).map(s => ({
        label: s.country === 'Canada' ? <div>{s.abbreviation} ({s.name}) <i className="fab fa-canadian-maple-leaf" style={{color: '#D80621'}}></i></div> : `${s.abbreviation} (${s.name})`,
        value: s.abbreviation
      }));
      return (
          <Select
              classNamePrefix={'Select'}
              {...this.props}
              options={stateOptions}
              value={stateOptions.find(option => option.value === this.props.value)}
              isClearable={!!this.props.isClearable}
              name='licenseStateDropdown'
          />
      );
    }
    return (
        <select
            className={this.props.className}
            value={this.props.value}
            onChange={this.props.onChange}
        >
          {this.props.placeholder && <option value={''}>{this.props.placeholder}</option>}
          {
            _.sortBy(states, ['abbreviation']).map(state => {
              return (
                  <option value={state.abbreviation} key={state.abbreviation}>
                    {state.abbreviation} ({state.name})
                  </option>
              )
            })
          }
        </select>
    );
  }

}