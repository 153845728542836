import React, {Fragment} from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

const multiFileUploadComponent = ({
                                    className,
                                    onFileSelect,
                                    fileList
                                  }) => {

  return (
      <Fragment>
        {fileList && fileList.length > 0 &&
        <div>
          <div className={'font-weight-bold'}>Files Currently Marked for Upload</div>
          <div className=" pl-3 pb-1 mt-2">
            {fileList.map((file, index) => {
              return <div key={`file${index}`} className=" pl-2">{file.name}</div>
            })
            }
          </div>
        </div>}
        <div className={'card ' + className}>
          <div className={'card-body'}>
            <div className={'form-group form-row'}>
              <Dropzone
                  onDrop={onFileSelect}
                  accept={'application/pdf'}
                  multiple={true}
              >
                {({getRootProps, getInputProps, isDragActive}) => {
                  return <div
                      style={{
                        width: '100%',
                        height: '100px'
                      }}
                  >
                    <div {...getRootProps()}
                         className={classNames({'dropzone--isActive': isDragActive, 'dropzone': !isDragActive})}
                         style={{width: '100%', height: '100%'}}
                    >
                      <input {...getInputProps()} />
                      {isDragActive
                          ? <div className={'text-center mt-4'}>Drop file here...</div>
                          :
                          <div className={'text-center mt-2'}>Drag a PDF file and drop it here, or click here to select
                            a
                            file</div>
                      }
                    </div>
                  </div>
                }}
              </Dropzone>
            </div>
          </div>
        </div>
      </Fragment>
  );
};

export default multiFileUploadComponent;