import types from './types';

const setDQTaskList = (taskList, taskListProcessed) => ({
  type: types.SET_DQ_TASKLIST,
  payload: {taskList, taskListProcessed}
});

const setDQTablePageIndex = (table, pageIndex) => ({
  type: types.SET_DQ_TABLE_PAGE_INDEX,
  payload: {table, pageIndex}
});

const setDQTablePageSize = (table, pageIndex, pageSize) => ({
  type: types.SET_DQ_TABLE_PAGE_SIZE,
  payload: {table, pageIndex, pageSize}
});

const setDQTableSorting = (table, columnId, desc) => ({
  type: types.SET_DQ_TABLE_SORTING,
  payload: {table, columnId, desc}
});

export default {
  setDQTaskList,
  setDQTablePageIndex,
  setDQTablePageSize,
  setDQTableSorting
};