import React from 'react';
import {FormGroup, Label, Input} from 'reactstrap';

const toggleMaskComponent = (props) => {
  return (
      <FormGroup tag="fieldset" className="dropdown-item" style={{marginBottom: '0'}}>
        <i className="fal fa-id-card"/>Mask SSN
        <div style={{marginLeft: '1.5em'}}>
          <FormGroup check inline>
            <Label check>
              <Input type="radio" value='on' name="ssnMaskSelect" defaultChecked
                     onChange={props.handleChangeMask}/>{' '}
              On
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input type="radio" value='off' name="ssnMaskSelect" onChange={props.handleChangeMask}/>{' '}
              Off
            </Label>
          </FormGroup>
        </div>
      </FormGroup>
  )
}
export default toggleMaskComponent;