import React, {Fragment} from 'react';
import TransferEmployeeModalContainer
  from '../../dqf/employees/EmployeeDetail/components/TransferEmployeeModal/TransferEmployeeModalContainer';
import CopySettingsModalContainer
  from '../../dqf/manage/admin/SiteLocationDetail/components/CopySettingsModal/CopySettingsModalContainer';
import PushSettingsModalContainer
  from '../../dqf/manage/admin/SiteLocationDetail/components/PushSettingsModal/PushSettingsModalContainer';

import OrderMVRModalContainer from '../../dqf/employees/EmployeeDetail/components/OrderMVRModal/OrderMVRModalContainer';
import VerificationOfFCRAContainer from '../../dqf/common/VerificationOfFCRAModal/VerificationOfFCRAModalContainer'
import AddAccidentModalContainer
  from '../../dqf/employees/EmployeeDetail/components/EmployeeAccidents/components/AddAccidentModal/AddAccidentModalContainer';
import DOTNumberModal
  from '../../dqf/manage/reports/Reports/Report/components/common/DOTNumberModal/DOTNumberModalContainer';
import DrugAlcoholQuestionModal
  from '../../dqf/employees/EmployeeDetail/components/EmployeeDrugAndAlcohol/components/DrugAlcoholQuestionModal/DrugAlcoholQuestionModalContainer';
import CSANotificationsModal from '../../dqf/common/CSANotificationsModal/CSANotificationsModalContainer';
import BulkTransferEmployeeModal from '../../dqf/employees/EmployeeList/components/BulkTransferEmployeeModalContainer';
import CMEResearchModal from '../../cme/CMEResearchModal/CMEResearchModalContainer';

const modalComponent = props => {
  let modal = null; //start with null so we don't display anything unless we are told to

  switch (props.displayModal) {
    case 'transfer': //if the displayModal value is transfer then display the transferEmployeeModal
      modal = <TransferEmployeeModalContainer info={props.info}/>;
      break;
    case 'copySettings':
      modal = <CopySettingsModalContainer info={props.info}/>;
      break;
    case 'pushSettings':
      modal = <PushSettingsModalContainer info={props.info}/>;
      break;
    case 'orderMVR':
      modal = <OrderMVRModalContainer info={props.info}/>;
      break;
    case 'verification':
      modal = <VerificationOfFCRAContainer info={props.info}/>;
      break;
    case 'addAccident':
      modal = <AddAccidentModalContainer info={props.info}/>;
      break;
    case 'dotNumberModal':
      modal = <DOTNumberModal info={props.info}/>;
      break;
    case 'drugAlcoholQuestionModal':
      modal = <DrugAlcoholQuestionModal info={props.info}/>;
      break;
    case 'csaNotificationsModal':
      modal = <CSANotificationsModal info={props.info}/>;
      break;
    case 'bulkTransfer':
      modal = <BulkTransferEmployeeModal info={props.info}/>;
      break;
    case 'CMEResearch':
      modal = <CMEResearchModal info={props.info}/>;
      break;
    default:
      break;
  }

  return (
      <Fragment>{modal}</Fragment>
  );
};

export default modalComponent;