import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {hasPermission} from '../../../../../utils/permissions';
import MenuHeadingComponent from './menuHeadingComponent';

export class MenuHeadingContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
      needClient: false,
      expanded: false
    }
  }

  checkNeedClient = () => {
    // See if Concorde user is logged in and no client selected in session
    const needClient = this.props.section.needClient && this.props.auth.user.isConcordeUser && !this.props.dqf.sites.sessionClient;
    this.setState({needClient});
  };

  componentDidMount() {
    // Do nothing if it's an "invisible" menu heading
    if (!this.props.section.text) {
      return;
    }
    if (this.props.auth.user.isConcordeUser) {
      this.checkNeedClient();
    }
  }

  componentDidUpdate(prevProps) {
    // Do nothing if it's an "invisible" menu heading
    if (!this.props.section.text) {
      return;
    }
    // If the user is a Concorde user and the session client changed, check if need client
    if (this.props.auth.user.isConcordeUser && prevProps.dqf.sites.sessionClient !== this.props.dqf.sites.sessionClient) {
      this.checkNeedClient();
    }
    // If the active route changed and the menu section is expanded, and the route is not in our section, collapse
    if (prevProps.location.pathname !== this.props.location.pathname && this.state.expanded && !this.props.location.pathname.startsWith(this.props.section.path)) {
        this.setState({expanded: false});
    }
  }

  handleClick = () => {
    if (this.state.needClient || this.state.expanded || !this.props.section.routes || this.props.section.routes.length === 0) {
      return;
    }
    // Find the first route that the user has permiossion for
      const route = this.props.section.routes.find(route => route.inMenu && (!route.permission || hasPermission(this.props.auth.user, route.permission)));
    if (!route) {
      return;
    }
    this.setState({expanded: true}, () => this.props.history.push(route.fullPath));
  };

  render() {
    // If the section heading has a permission, make sure the user has it
    if (this.props.section.permission && !hasPermission(this.props.auth.user, this.props.section.permission)) {
      return null;
    }
    // If the section has section components, make sure the user has the permissions
    let sectionComponents = null;
    if (this.props.section.sectionComponents) {
      sectionComponents = this.props.section.sectionComponents.reduce((sectionComponents, sectionComponent, index) => {
        if (!sectionComponent.permission || hasPermission(this.props.auth.user, sectionComponent.permission)) {
          sectionComponents.push(<sectionComponent.component key={index}/>);
        }
        return sectionComponents;
      }, []);
    }
    // See if the user has permission for any of the routes in the section
    let hasAnyPermission = false;
    if (this.props.section.routes) {
      this.props.section.routes.forEach(route => {
        if (route.inMenu && (!route.permission || hasPermission(this.props.auth.user, route.permission))) {
          hasAnyPermission = true;
        }
      });
    }
    // If user has no permission, do not render
    if (!hasAnyPermission) {
      return null;
    }

    return (
        <MenuHeadingComponent
            path={this.props.path + this.props.section.path}
            text={this.props.section.text}
            components={sectionComponents}
            routes={this.props.section.routes}
            expanded={this.state.expanded}
            needClient={this.state.needClient}
            onClick={this.handleClick}
        />
    );
  }

};

const mapStateToProps = state => ({
  auth: state.auth,
  dqf: state.dqf
});

export default withRouter(connect(mapStateToProps, null)(MenuHeadingContainer));