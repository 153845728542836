import actions from './actions';
import axios from "axios";
import moment from "moment";
import internalDQAPI from '../../services/dqInternalProcesses';
import DQInternalActivities from '../../config/DQInternalActivities.json';
import {layoutOperations} from '../layout';


const getDQTaskList = (user, showLoadingPanel) => dispatch => {
  if (showLoadingPanel) {
    dispatch(layoutOperations.showLoadingPanel());
  }
  axios
  .get('/v1/processes/internalReviewProcess/tasks')
  .then(res => {
    const mappedTaskList = res.data ? mapCamundaVariables(res.data) : null;
    if (!mappedTaskList) {
      dispatch(actions.setDQTaskList(mappedTaskList, null));
    } else {
      processTaskListData(mappedTaskList, user)
      .then((processedTaskList) => {
        if (showLoadingPanel) {
          dispatch(layoutOperations.hideLoadingPanel());
        }
        dispatch(actions.setDQTaskList(mappedTaskList, processedTaskList));
      })
      .catch((err) => {
        console.error(err);
        dispatch(actions.setDQTaskList(mappedTaskList, null));
      })
    }
  })
  .catch(err => {
    console.error(err);
  });
};

/**
 * Create property for each Camunda Variable on the root / parent object.
 * After all variables are mapped to the root task object the variables collection is deleted
 */
const mapCamundaVariables = taskList => { //TODO update for DQ
  for (const [, camTask] of Object.entries(taskList)) {
    if (camTask.variables) {
      camTask.variables.forEach(v => {
        let variableValue = v.value;
        if (v.type === 'Date' && v.value) {
          variableValue = moment(v.value);
        }
        if (v.name === 'received') {
          camTask.daysInQueue = moment(Date.now()).diff(moment(v.value), 'days');
        }
        camTask[v.name] = variableValue;
      });
      delete camTask.variables;
    }
  }
  return Object.keys(taskList).map(key => taskList[key]);
};

const _stepTitles = [
  "IMS Emails (Unmatched)",
  "IMS Emails (Matched)",
  "External Uploads",
  "Internal Uploads",
  "Document Rejections",
  "Retrieve Pre-Hire Docs",
  "Missing Cover Sheets"
];
/**
 * when the tasklist is updated sort it into the proper counts and get the list of dq items and do the same
 * @param taskListData
 * @param user
 */
const processTaskListData = (taskListData, user) => {
  return new Promise((resolve, reject) => {
    const processedData = {};
    _stepTitles.forEach((title) => {
      processedData[title] = {toDo: 0, PDFsToDo: 0, doneToday: new Set(), doneByMeToday: new Set(), step: title}
    });
    // Emails can be tagged in the subject line with  Tag:XXXXX
    // In case tags are set with different case, maintain a distinct list of uppercase tags.
    // The display value will correspond to the first one that we encounter.
    // (Note that now, "Internal Uploads" can also be tagged, within the "pdfimporter" utility.)
    const tags = {};
    taskListData.forEach((task) => {
      if (task.errorTag) { //don't count it if it has an error tag
        return;
      }
      switch (task.formKey) {
        case 'review':
          if (task.firstReviewer) {
            ++processedData['Document Rejections'].toDo;
          } else if (task.webUpload) {
            if (task.externalUser) {
              ++processedData['External Uploads'].toDo;
            } else {
              //  It's an internal upload, maybe from the pdfimporter, so see if there's a tag
              if (task.tag) {
                // There's a tag -- make sure we have it in our tag set
                const upperCaseTag = task.tag.toUpperCase();
                if (!tags.hasOwnProperty(upperCaseTag)) {
                  tags[upperCaseTag] = task.tag;
                }
                const worklistRow = `(IMPORTED) TAG: ${upperCaseTag}`;
                if (!processedData[worklistRow]) {
                  processedData[worklistRow] = {
                    step: 'Internal Uploads',
                    tag: tags[upperCaseTag],
                    toDo: 0,
                    doneByMeToday: new Set(),
                    doneToday: new Set()
                  };
                }
                ++processedData[worklistRow].toDo;
              } else {
                // No tag; it goes into the "generic" bucket
                ++processedData['Internal Uploads'].toDo;
              }
            }
          } else if (task.email) {
            ++processedData['IMS Emails (Matched)'].toDo;
            processedData['IMS Emails (Matched)'].PDFsToDo += task.PDFsToReview;
          }
          break;
        case 'externalDocs':
          ++processedData['Retrieve Pre-Hire Docs'].toDo;
          break;
        case 'sortSearchMatch':
          // See if there's a tag
          if (task.tag) {
            // There's a tag -- make sure we have it in our tag set
            const upperCaseTag = task.tag.toUpperCase();
            if (!tags.hasOwnProperty(upperCaseTag)) {
              tags[upperCaseTag] = task.tag;
            }
            const worklistRow = `TAG: ${upperCaseTag}`;
            if (!processedData[worklistRow]) {
              processedData[worklistRow] = {
                step: 'IMS Emails (Unmatched)',
                tag: tags[upperCaseTag],
                toDo: 0,
                doneByMeToday: new Set(),
                doneToday: new Set()
              };
            }
            ++processedData[worklistRow].toDo;
          } else {
            // No tag; it goes into the "generic" bucket
            ++processedData['IMS Emails (Unmatched)'].toDo;
          }
          break;
        case 'waitingForInfo':
          ++processedData['Missing Cover Sheets'].toDo;
          break;
        default:
          break;
      }
    });
    const startDate = new moment().utc().set({hour: 0, minute: 0, second: 0, millisecond: 0});
    const endDate = new moment().utc().set({hour: 23, minute: 59, second: 59, millisecond: 999});

    internalDQAPI.getDQItemActivity(startDate, endDate)
    .then(dqItems => {
      dqItems.forEach(item => {
        if (!item.activities || item.activities.length === 0) {
          return;
        }
        item.activities.forEach(activity => {
          if (moment.utc(activity.timestamp).isBetween(startDate, endDate, 'day', '[]')) {
            switch (activity.activity) {
              case DQInternalActivities.REJECTED_EMAIL_NO_COVER.activityTag:
              case DQInternalActivities.DELETED_EMAIL.activityTag:
              case DQInternalActivities.SENT_EMAIL_TO_CME.activityTag:
              case DQInternalActivities.DISCARDED_DOCUMENT.activityTag:
              case DQInternalActivities.CREATED_NEW_EMPLOYEE_FILE.activityTag:
              case DQInternalActivities.ASSIGNED_DOCUMENT.activityTag:
                _tallySortMatchEmailActivity(processedData, tags, item, activity, user);
                break;
              case DQInternalActivities.ACCEPTED_DOCUMENT_CLASSIFICATION.activityTag:
              case DQInternalActivities.REJECTED_DOCUMENT_CLASSIFICATION.activityTag:
              case DQInternalActivities.CORRECTED_DOCUMENT_CLASSIFICATION.activityTag:
                _tallyReviewUploadActivity(processedData, item, activity, user);
                break;
              case DQInternalActivities.MARKED_BAD_DOCUMENT.activityTag:
                // Could happen during email sort/match or during review of web upload
                if (item.uploadedDocumentIds && item.uploadedDocumentIds.length > 0) {
                  _tallyReviewUploadActivity(processedData, item, activity, user);
                } else {
                  _tallySortMatchEmailActivity(processedData, tags, item, activity, user);
                }
                break;
              case DQInternalActivities['IMPORTED_PRE-HIRE_DOCUMENT'].activityTag:
                processedData['Retrieve Pre-Hire Docs'].doneToday.add(item._id);
                if (user.id === activity.user) {
                  processedData['Retrieve Pre-Hire Docs'].doneByMeToday.add(item._id);
                }
                break;
              default:
                break;
            }
          }
        });
      });
      resolve(Object.values(processedData));
    })
    .catch(err => {
      console.error(err);
      reject(err)
    });
  });
};

// Utility method to tally an activity that happened during email sort/match
const _tallySortMatchEmailActivity = (processedData, tags, item, activity, user) => {
  let worklistRow = 'IMS Emails (Unmatched)';
  if (item.tag) {
    // There's a tag -- make sure we have it in our tag set
    const upperCaseTag = item.tag.toUpperCase();
    if (!tags.hasOwnProperty(upperCaseTag)) {
      tags[upperCaseTag] = item.tag;
    }
    worklistRow = `TAG: ${upperCaseTag}`;
    if (!processedData[worklistRow]) {
      processedData[worklistRow] = {
        step: 'IMS Emails (Unmatched)',
        tag: tags[upperCaseTag],
        toDo: 0,
        doneByMeToday: new Set(),
        doneToday: new Set()
      };
    }
  }
  processedData[worklistRow].doneToday.add(item._id);
  if (user.id === activity.user) {
    processedData[worklistRow].doneByMeToday.add(item._id);
  }
};

// Utility method to tally an activity that happened during upload review
const _tallyReviewUploadActivity = (processedData, item, activity, user) => {
  const uploaded = item.uploadedDocumentIds && item.uploadedDocumentIds.length > 0;
  if (uploaded) {
    const startingActivity = item.activities.find(action => action.activity.includes('Uploaded'));
    if (!startingActivity) {
      return;
    }
    if (!!item.firstReviewer && !!item.reviewer) {
      processedData['Document Rejections'].doneToday.add(item._id);
      if (user.id === activity.user) {
        processedData['Document Rejections'].doneByMeToday.add(item._id);
      }
    } else { //is sparc
      if (startingActivity.activity.includes('external')) {
        processedData['External Uploads'].doneToday.add(item._id);
        if (user.id === activity.user) {
          processedData['External Uploads'].doneByMeToday.add(item._id);
        }
      } else {
        processedData['Internal Uploads'].doneToday.add(item._id);
        if (user.id === activity.user) {
          processedData['Internal Uploads'].doneByMeToday.add(item._id);
        }
      }
    }
  } else {
    processedData['IMS Emails (Matched)'].doneToday.add(item._id);
    if (user.id === activity.user) {
      processedData['IMS Emails (Matched)'].doneByMeToday.add(item._id);
    }
  }
};

// We could just call these table dispatches directly, but I think this is technically best practice
const updateDQTablePageIndex = (table, pageIndex) => (dispatch) => {
  dispatch(actions.setDQTablePageIndex(table, pageIndex)); // update the globle state
}

const updateDQTablePageSize = (table, pageIndex, pageSize) => (dispatch) => {
  dispatch(actions.setDQTablePageSize(table, pageIndex, pageSize)); // update the globle state
}

const updateDQTableSorting = (table, columnId, desc) => (dispatch) => {
  dispatch(actions.setDQTableSorting(table, columnId, desc)); // update the globle state
}


export default {
  getDQTaskList,
  updateDQTablePageIndex,
  updateDQTablePageSize,
  updateDQTableSorting
};