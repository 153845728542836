import React from 'react';

const orderStatusSelectComponent = props => {
  const {statuses} = props;
  let options = [];
  if (statuses) {
    options = statuses
        .map(s => (
            <option id={`orderStatus_${s.OrderStatusID}`}
                    key={s.OrderStatusID}
                    value={s.OrderStatusID}>{s.OrderStatus}</option>
        ));
  }

  return (
      <select id="status"
              name="status"
              className="form-control"
              value={props.selectedOrderStatusId}
              onChange={props.onStatusChanged}
      >
        <option value="-1">Select Order Status</option>
        {options}
      </select>
  )

};

export default orderStatusSelectComponent;