import React from 'react';

import SelectorMode from '../SitesSelector/components/ClientSiteSelector/clientSiteSelectorComponent';

const simpleSitesSelectorComponent = props => {
  return (
      <SelectorMode
          displaySites={props.displaySites}
          selectAll={props.selectAll}
          allSitesSelected={props.allSitesSelected}
          multiSelect={false}
          selectedSites={props.selectedSites}
          placeholder={props.placeholder}
          hideSelect={props.hideSelect}
          selectedSite={props.selectedSite}
          selectIsDisabled={props.selectIsDisabled}
          onSitesChanged={props.onSitesChanged}
      />
  );
};

export default simpleSitesSelectorComponent;