import React, {Component} from 'react';
import {connect} from 'react-redux';
import CMEResearchModalComponent from './cmeResearchModalComponent';
import {modalOperations} from '../../../ducks/modal';
import axios from 'axios';
import {layoutOperations} from '../../../ducks/layout';
import {alertsOperations} from '../../../ducks/alerts';
import ordersService from '../../../services/ordersService'
import _ from 'lodash';
import employeeService from '../../../services/employeeService';
import async from 'async';

export class CMEResearchModalContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showDocuments: false
    };

  }

  componentDidMount() {
    this.cancelSource = axios.CancelToken.source();
  }

  componentWillUnmount() {
    this.cancelSource.cancel('userCanceled');
  }

  handleHideModal = () => {
    this.props.hideModal(true);
  };

  handleSearchButtonClick = e => {
    e.preventDefault();
    if (!this.state.searchQuery) {
      this.props.addAlert('You must enter search criteria.', 'danger');
      return;
    }
    if (this.state.searchQuery && this.state.searchQuery.length > 0) {
      this.props.showLoadingPanel();
      axios
      .get(`/v1/employees?search=${this.state.searchQuery}&isConcordeUser=true&exactMatch=true`)
      .then(res => {
        const searchResults = res ? res.data : [];
        if (searchResults.length === 1) { //if there is only one result, auto select it.
          this.selectionChanged(null, searchResults[0]);
        } else {
          this.setState({
            searchResults, employeeId: null, orderId: null, employeeInfo: null, orderResults: null
          });
        }
        this.props.hideLoadingPanel();
      })
      .catch(err => {
        console.error(err);
        this.props.hideLoadingPanel();
      });
    }
  };

  handleSearchInputChanged = e => {
    e.preventDefault();
    this.setState({searchQuery: e.target.value});
  };

  //employee selection changed
  selectionChanged = (e, rowInfo) => {
    if (e) {
      e.preventDefault();
    }
    //search for orders and documents
    this.props.showLoadingPanel();
    this.setState({
      employeeId: rowInfo.employeeId,
      searchResults: null,
      employeeInfo: rowInfo,
      selectedDocumentId: null
    });

    async.parallel([
          done => {
            employeeService.getCMEDocuments(rowInfo.employeeId, this.cancelSource.token)
            .then(docList => {
              const sortedArray = docList.sort((a, b) => {
                if (a.docDate < b.docDate) {
                  return -1;
                }
                if (a.docDate > b.docDate) {
                  return 1;
                }
                // a must be equal to b
                return 0;
              });
              this.setState({docList: sortedArray});
              done();
            })
            .catch(err => {
              if (err !== 'userCanceled') {
                console.error(err);
              }
              done(err);
            });
          },
          done => {
            ordersService.searchOrders(`employmentId=${rowInfo.employeeId}`, this.cancelSource.token)
            .then((results) => {
              const orderResults = results && results.orderResults ? results.orderResults : [];
              this.setState({orderResults});
              done();
            }).catch(err => {
              console.error(err);
              this.setState({orderResults: []});
              done(err);
            });
          }
        ], err => {
          this.props.hideLoadingPanel();
        }
    );
  };

  orderSelectionChanged = (selectedOrder) => {
    //we are selecting the order now, not the employee
    const orderResults = _.cloneDeep(this.state.orderResults);
    if (this.state.orderId) {//if there is one selected already
      //unselect it
      const index = orderResults.findIndex((result) => result.selected);
      if (index !== -1) {
        orderResults[index].selected = false;
      }
    }
    const index = orderResults.findIndex((result) => result.OrderID === selectedOrder);
    if (index === -1) {
      this.props.addAlert('Order is not valid. Audit trail could not be loaded', 'danger');
      return;
    }
    orderResults[index].selected = true;
    ordersService.getAuditTrail(selectedOrder, this.cancelSource.token)
    .then(auditTrail => {
      this.setState({orderId: selectedOrder, orderResults, auditTrail});
    })
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error(err);
      }
    });
  };

  documentListClickRowHandler = (original) => {
    const docList = _.cloneDeep(this.state.docList);
    if (this.state.selectedDocumentId) {//if there is one selected already
      //unselect it
      const index = docList.findIndex((result) => result.selected);
      if (index !== -1) {
        docList[index].selected = false;
      }
    }

    const index = docList.findIndex((result) => result.imageId === original.imageId);
    if (index === -1) {
      this.props.addAlert('Document is not valid and could not be loaded', 'danger');
      return;
    }
    docList[index].selected = true;
    this.setState({selectedDocumentId: original.imageId, docList});
  };

  handleShowDocuments = () => {
    this.setState({showDocuments: !this.state.showDocuments})
  };

  //this is blank because it's a required property that is used elsewhere to basically reload the page, but we don't need it here
  handleOrderChanged = () => {
  };

  handleOrderChangedCallback = (orderId, error) => {
    if (error) {
      this.props.addAlert('The order update did not complete.', 'danger');
    }
    ordersService.searchOrders(`employmentId=${this.state.employeeId}`, this.cancelSource.token)
    .then((results) => {
      const orderResults = results && results.orderResults ? results.orderResults : [];
      this.setState({orderResults});
    }).catch(err => {
      console.error(err);
      this.setState({orderResults: []});
    });
    if (this.state.auditTrail) {
      ordersService.getAuditTrail(this.state.orderId, this.cancelSource.token)
      .then(auditTrail => {
        this.setState({auditTrail});
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
    }
  };

  handleChangeMessageText = (e) => {
    this.setState({newMessageText: e.target.value})
  };

  handleAddMessage = (e) => {
    e.preventDefault();
    ordersService.addAuditTrail(this.state.orderId, this.state.newMessageText, this.cancelSource.token)
    .then(() => {
      this.props.addAlert('Message successfully added');
      ordersService.getAuditTrail(this.state.orderId, this.cancelSource.token)
      .then(auditTrail => {
        this.setState({auditTrail, newMessageText: ''});
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
    }).catch(err => {
      console.error(err);
      this.props.addAlert('The message could not be added.', 'danger');
    });
  };

  render() {
    return (
        <CMEResearchModalComponent
            onHideModal={this.handleHideModal}
            onSearchButtonClick={this.handleSearchButtonClick}
            onSearchInputChanged={this.handleSearchInputChanged}
            employeeId={this.state.employeeId}
            searchResults={this.state.searchResults}
            selectionChanged={this.selectionChanged}
            orderResults={this.state.orderResults}
            orderSelectionChanged={this.orderSelectionChanged}
            auditTrail={this.state.auditTrail}
            employeeInfo={this.state.employeeInfo}
            selectedDocumentId={this.state.selectedDocumentId}
            documentListClickRowHandler={this.documentListClickRowHandler}
            docList={this.state.docList}
            onShowDocuments={this.handleShowDocuments}
            showDocuments={this.state.showDocuments}
            onOrderChangedCallback={this.handleOrderChangedCallback}
            onOrderChanged={this.handleOrderChanged}
            userEmail={this.props.auth.user.email}
            onChangeMessageText={this.handleChangeMessageText}
            onAddMessage={this.handleAddMessage}
            newMessageText={this.state.newMessageText}
        />
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  showLoadingPanel: layoutOperations.showLoadingPanel,
  hideLoadingPanel: layoutOperations.hideLoadingPanel,
  addAlert: alertsOperations.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(CMEResearchModalContainer);