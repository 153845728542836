import React from 'react';
import moment from 'moment';
import DateInputWrapper from '../../../../common/DateWrapper/DateInputWrapperContainer';
import OrderStatusSelectContainer from '../../../../cme/common/OrderStatusSelect/OrderStatusSelectContainer';

const orderStatusFormComponent = props => {
  const {orderInfo} = props;
  let {buttonWrapClassName} = props;
  if (!buttonWrapClassName) {
    buttonWrapClassName = 'form-group form-row'
  }
  return (
      <form autoComplete="new-password">
        {!props.hideOrderStatus &&
        <div className="form-group form-row">
          <label htmlFor="status" className="col-sm-3 col-form-label">Status</label>
          <div className="col-sm-7">
            <OrderStatusSelectContainer
                handleOrderStatusChange={props.handleOrderStatusChange}
                selectedOrderStatusId={orderInfo.orderStatusId}
            />
          </div>
        </div>
        }
        <div className="form-group form-row">
          <label htmlFor="appointmentDate" className="col-sm-3 col-form-label">Appt. Date</label>
          <div className="col-sm-7">
            <DateInputWrapper
                dateFormat={orderInfo.walkIn ? "M/D/YYYY" : "M/D/YYYY h:mm a"}
                timeFormat={'h:mm a'}
                timeIntervals={15}
                showTimeSelect={!orderInfo.walkIn}
                selected={orderInfo.appointmentDate ? moment.parseZone(orderInfo.appointmentDate) : null}
                showDisabledMonthNavigation
                placeholderText="mm/dd/yyyy"
                onChangeHandler={props.handleAppointmentDateChange}
                name="appointmentDate"
                className="form-control"
                autoComplete="new-password"
                id="appointmentDate"
                dropdownMode="scroll"
            />
          </div>
        </div>
        <div className="form-group form-row">
          <div className="col-sm-3"/>
          <label htmlFor="vendor" className="col-sm-1 col-form-label">Walk-In?</label>
          <div className="col-sm-1 mt-2">
            <input
                type="checkbox"
                id="walkIn"
                value={orderInfo.walkIn}
                checked={!!orderInfo.walkIn}
                onChange={props.onCheckboxChange}/>
          </div>
        </div>
        <div className="form-group form-row">
          <label htmlFor="vendor" className="col-sm-3 col-form-label">Clinic</label>
          <div className="col-sm-7">
            <input name="vendor"
                   type="text"
                   className="form-control"
                   id="vendor"
                   placeholder="clinic"
                   value={orderInfo.vendor || ''}
                   readOnly/>
          </div>
          <div className="col-sm-2">
            <button className="btn btn-link" onClick={props.handleVendorLookup}><i className="fas fa-pencil-alt"/>
            </button>
          </div>
        </div>
        <div className={buttonWrapClassName}>
          <button className="btn btn-primary" onClick={props.handleUpdateOrder}>Update</button>  {/* calls handleUpdateOrder() in EditOrderStatusContainer.js */}
          <button className="btn btn-secondary" onClick={props.handleCancelEditOrder}>Cancel</button>
        </div>
      </form>
  );
};

export default orderStatusFormComponent;