import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import ToggleMaskContainer from './ToggleMaskContainer';

const authenticatedLinksComponent = props => {
  const {isAuditor, onLogoutClicked, auth: {user}} = props;
  return (
      <div className="top-nav d-flex flex-row">
        {!user.isConcordeUser &&
          <div className='mr-3 ml-1'>
            <Link to="/contact">
              Contact Us <i className="fas fa-envelope"/>
            </Link>
          </div>
        }
        <div>
          <ul className="nav">
            <li className="dropdown">
              <button
                  data-toggle="dropdown"
                  className="dropdown-toggle nav-link username btn btn-link btn-sm p-0 border-0"
              >
                <i className="fas fa-user-circle"/> {user.firstName} {user.lastName}
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {!isAuditor &&
                <>
                  <Link to="/user/profile" className="dropdown-item">
                    <i className="fas fa-user"/>My Profile
                  </Link>
                  <ToggleMaskContainer/>
                </>
                }
                <Link to="/" className="dropdown-item" onClick={onLogoutClicked}>
                  <i className="fas fa-sign-out-alt"/> Log Out
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
  )
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(authenticatedLinksComponent);