const INCREMENT_CURRENT_STEP_BY_1 = 'INCREMENT_CURRENT_STEP_BY_1';
const DECREMENT_CURRENT_STEP_BY_1 = 'DECREMENT_CURRENT_STEP_BY_1';
const RESET_ALL_STEPS_GLOBAL_STATE = 'RESET_ALL_STEPS_GLOBAL_STATE';
const SET_POOL_TO_EDIT = 'SET_POOL_TO_EDIT';
const SET_RANDOMS_POOL_WIZARD_CRITICAL_ERROR = 'SET_RANDOMS_POOL_WIZARD_CRITICAL_ERROR';
const SET_RANDOMS_POOL_WIZARD_HAVE_FETCHED_DATA = 'SET_RANDOMS_POOL_WIZARD_HAVE_FETCHED_DATA';
const SET_RANDOMS_POOL_WIZARD_OK_TO_PROCEED = 'SET_RANDOMS_POOL_WIZARD_OK_TO_PROCEED';
const SET_SHOW_RANDOMS_POOL_WIZARD = 'SET_SHOW_RANDOMS_POOL_WIZARD';

export default {
  INCREMENT_CURRENT_STEP_BY_1,
  DECREMENT_CURRENT_STEP_BY_1,
  RESET_ALL_STEPS_GLOBAL_STATE,
  SET_POOL_TO_EDIT,
  SET_RANDOMS_POOL_WIZARD_CRITICAL_ERROR,
  SET_RANDOMS_POOL_WIZARD_HAVE_FETCHED_DATA,
  SET_RANDOMS_POOL_WIZARD_OK_TO_PROCEED,
  SET_SHOW_RANDOMS_POOL_WIZARD
}