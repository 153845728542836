import axios from 'axios';

const getEmployeeInfo = (employeeId, isAuditor, cancelToken = null, docAndOrders = true) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}?${docAndOrders ? 'with=docInfo,orders' : ''}${isAuditor ? '&auditor' : ''}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to load employee file', err);
      reject(err);
    });
  });
};

const getEmployeeDocTypes = (employeeId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/doctypes?includeSDU=true`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to get employee doc types', err);
      reject(err);
    });
  });
};

const copyEmployeeDocuments = (fromEmployeeId, toEmployeeId, docsMap, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .post(`/v1/employees/${toEmployeeId}/mergeEmployees?fromEmployeeId=${fromEmployeeId}`, {docsMap}, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to copy employee docs', err);
      reject(err);
    });
  });
};

const updateEmployee = (employeeId, updates, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .patch(`/v1/employees/${employeeId}`, updates, {cancelToken})
    .then(response => {
      return resolve();
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    });
  });
};

const deleteEmployee = (employeeId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .delete(`/v1/employees/${employeeId}`, {cancelToken})
    .then(response => {
      return resolve();
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    });
  });
};

const getEmployeeDrugAlcoholInfo = (employeeId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/drugAlcoholInfo`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to load employee drug and alcohol data', err);
      reject(err);
    });
  });
};

const getCMEDocuments = (employeeId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/cmeDocuments`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to load employee documents', err);
      reject(err);
    });
  });
};


const getMatchingDocuments = (employeeId, docTypeId, docDate, docExpirationDate, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/documents?docTypeId=${docTypeId}&docDate=${docDate}${docExpirationDate ? `&docExpirationDate=${docExpirationDate}` : ''}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to load employee documents', err);
      reject(err);
    });
  });
};

const getDCHConsents = (employeeId, license, state, dotNumber, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/dchConsents?license=${license}&state=${state}&dotNumber=${dotNumber}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to find dch consents documents', err);
      reject(err);
    });
  });
};

const getEmployeeRuleMatches = (employeeId, ruleId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/employees/${employeeId}/ruleMatches/${ruleId}`, {cancelToken})
    .then(response => {
      return resolve(response.data);
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      console.error('Failed to find rule matches', err);
      reject(err);
    });
  });
};

const findMVR = async (employeeId, docImageId, cancelToken = null) => {
  return (await axios.get(`/v1/employees/${employeeId}/findMVR?docImageId=${docImageId}`, {cancelToken})).data;
}

export default {
  getEmployeeInfo,
  getEmployeeDocTypes,
  copyEmployeeDocuments,
  updateEmployee,
  deleteEmployee,
  getEmployeeDrugAlcoholInfo,
  getCMEDocuments,
  getMatchingDocuments,
  getDCHConsents,
  getEmployeeRuleMatches,
  findMVR
};