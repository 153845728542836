import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import CSANotificationsModal from './csaNotificationsModalComponent';
import {alertsOperations} from '../../../../ducks/alerts';
import {modalOperations} from "../../../../ducks/modal";
import {Rnd} from "react-rnd";
import _ from 'lodash';

class CSANotificationsModalContainer extends Component {
  constructor(props) {
    super(props);

    //the props are coming in on the info prop of the modal container, so map them to the ones we need
    this.state = {
      modalDisplayInfo: props.info && props.info.modalDisplayInfo ? props.info.modalDisplayInfo : null,
      basics: props.info && props.info.basics ? props.info.basics : [],
      editMode: (props.info && props.info.editMode) || false
    };
  }

  handleHideCSADetailModal = () => {
    this.props.hideModal(true);
  };

  handleSaveCSADetail = () => {
    this.props.hideModal(true, {modalDisplayInfo: this.state.modalDisplayInfo});
  };

  handleSelectAll = (e, basic) => {
    const modalDisplayInfo = _.cloneDeep(this.state.modalDisplayInfo);
    modalDisplayInfo.types.forEach((type) => type[basic] = e.target.checked);
    this.setState({modalDisplayInfo});
  };

  handleSelectAllType = (e, typeName) => {
    const modalDisplayInfo = _.cloneDeep(this.state.modalDisplayInfo);
    const typeToChange = modalDisplayInfo.types.findIndex((type) => type.typeName === typeName);
    this.state.basics.forEach((basic) => {
      modalDisplayInfo.types[typeToChange][basic.BasName] = e.target.checked
    });
    this.setState({modalDisplayInfo});
  };

  handleNotificationChange = (e, basic, type) => {
    const modalDisplayInfo = _.cloneDeep(this.state.modalDisplayInfo);
    const index = modalDisplayInfo.types.findIndex((item) =>
        item.typeName === type);
    modalDisplayInfo.types[index][basic] = !modalDisplayInfo.types[index][basic];
    this.setState({modalDisplayInfo});
  };

  render() {
    const {modalDisplayInfo, basics, editMode} = this.state;
    if (!modalDisplayInfo) {
      return null;
    }
    let title = 'CSA Detail Notifications';
    let subtitle = '';
    if (modalDisplayInfo.hasOwnProperty('email')) {
      title += ` - ${modalDisplayInfo.email}`;
      subtitle = `Location: ${modalDisplayInfo.clientName}`
    } else {
      title += ` - (for ALL recipients!)`;
    }
    const toggleAllCheckStatuses = {};
    basics.forEach((basic) => {
      toggleAllCheckStatuses[basic.BasName] = modalDisplayInfo.types.filter((type) => type[basic.BasName]).length;
    });
    modalDisplayInfo.types.forEach((type) => {
      toggleAllCheckStatuses[type.typeName] = Object.values(type).filter((value) => value === true).length;
    });

    return (
        <Modal isOpen={true} size={'lg'}>
          <Rnd dragHandleClassName={'modal-header'} enableResizing={false}>
            <ModalHeader className={'cursor-move'}>{title}</ModalHeader>
            <ModalBody>
              <div className={'container p-0'}>
                <div className={'row ml-0'}>
                  {subtitle}
                </div>
                <div>
                  <CSANotificationsModal
                      data={modalDisplayInfo.types}
                      basics={basics}
                      onNotificationChange={this.handleNotificationChange}
                      onSelectAll={this.handleSelectAll}
                      onSelectAllType={this.handleSelectAllType}
                      toggleAllCheckStatuses={toggleAllCheckStatuses}
                      editMode={editMode}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {editMode &&
                  <button type={'button'} className={'btn btn-sm btn-primary'} onClick={this.handleSaveCSADetail}>
                    Save
                  </button>
              }
              <button
                  type={'button'}
                  className={editMode ? 'btn btn-sm btn-secondary' : 'btn btn-sm btn-primary'}
                  onClick={this.handleHideCSADetailModal}
              >
                {editMode ? 'Close' : 'Cancel'}
              </button>
            </ModalFooter>
          </Rnd>
        </Modal>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  hideModal: modalOperations.hideModal,
  addAlert: alertsOperations.addAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(CSANotificationsModalContainer);