import React, {Fragment} from 'react';
import Select from 'react-select';
import StateSelect from '../../../../../common/StateSelect/StateSelect';
import DateInputWrapper from '../../../../../common/DateWrapper/DateInputWrapperContainer';
import moment from 'moment';
import classNames from 'classnames';

import LicenseNumberFormatOverride
  from '../../../../../common/DriverLicenseNumberFormatOverride/driverLicenseNumberFormatOverrideComponent';

const orderMVRModalComponent = props => {

  const licenseClasses = props.licenseClassList ? props.licenseClassList.map(c => ({
    value: c.licenseClassId,
    label: c.code
  })) : [];

  return (
      <Fragment>
        <div className={'mx-2 mb-3'}>
          {props.message}
        </div>
        <div className={'form-group row'}>
          <label className={'col-sm-4 col-form-label'}>Birth Date <span className={'text-danger'}>*</span></label>
          <div className={'datepicker-wrapper col-sm-8'}>
            <DateInputWrapper selected={props.employeeInfo.dob ? moment(props.employeeInfo.dob) : null}
                              maxDate={moment()}
                              dateFormat={"M/D/YYYY"}
                              placeholderText="mm/dd/yyyy"
                              onChangeHandler={(e) => props.onDateChange(e, "dob")}
                              name="dob"
                              id="dob"
                              autoComplete="new-password"
                              className={!props.employeeInfo.dob ? "is-invalid form-control-sm form-control" : "form-control-sm form-control"}
                              dropdownMode={'scroll'}
            />
          </div>
        </div>
        <div className={'form-group row'}>
          <label className={'col-sm-4 col-form-label'}>Number <span className={'text-danger mr-1'}>*</span></label>
          <div className={'col-sm-8'}>
            <input type="text" autoComplete="new-password"
                   className={classNames({
                     'form-control form-control-sm': true,
                     'is-invalid': !props.employeeInfo.license || props.employeeInfo.license.length === 0 || (!props.overrideLicenseValidation && props.licenseFormatInvalid)
                   })}
                   name="license"
                   onChange={props.onChange}
                   value={props.employeeInfo.license ? props.employeeInfo.license : ''}/>
            {props.licenseFormatInvalid &&
            <LicenseNumberFormatOverride
                state={props.employeeInfo.state}
                overrideLicenseValidation={props.overrideLicenseValidation}
                onOverrideValidation={props.onOverrideValidation}
                driversLicenseFormatRules={props.driversLicenseFormatRules}
            />
            }
          </div>
        </div>
        <div className={'form-group row'}>
          <label className={'col-sm-4 col-form-label'}>State <span className={'text-danger'}>*</span></label>
          <div className={'col-sm-8'}>
            <span className="flex-fill">
                    <StateSelect name="state"
                                 id="state"
                                 className={classNames({
                                   'is-invalid': !props.employeeInfo.state || props.employeeInfo.state.trim().length === 0 ||
                                       (!props.overrideLicenseValidation && props.licenseFormatInvalid)
                                 })}
                                 placeholder="State"
                                 value={props.employeeInfo.state}
                                 onChange={(e) => props.onSelectChange(e, "state")}
                    />
          </span>
          </div>
        </div>
        <div className={'form-group row'}>
          <label className={'col-sm-4 col-form-label'}>Class <span className={'text-danger'}>*</span></label>
          <div className={'col-sm-8'}>
          <span className="flex-fill">
                    <Select
                        classNamePrefix={'Select'}
                        placeholder={'Class'}
                        options={licenseClasses}
                        className={!props.employeeInfo.licenseClassId ? "is-invalid" : ""}
                        value={licenseClasses.filter(option => option.value === props.employeeInfo.licenseClassId)[0]}
                        onChange={e => props.onSelectChange(e, "licenseClassId")}
                    />
          </span>
          </div>
        </div>
      </Fragment>
  );
};

export default orderMVRModalComponent;