import React from 'react';

const Spinner = props => {
  return (
      <div className="spinnerOverlay">
        <img
          src="/images/sparc-spinner-fluid-150-percent-speed.gif"
          alt="Working . . ."
          style={{ width: '50px', height: '50px' }}
        />
      </div>
  );
};

export default Spinner;