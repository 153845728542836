import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {alertsOperations} from '../../../ducks/alerts';
import {sessionOperations} from '../../../ducks/userSession';

const ProtectedRoute = (props) => {
  const {component: Component, auth, permission, addAlert, ...rest} = props;

  if (!auth.isAuthenticated && props.userSession.status === 'ACTIVE') {
    return null;
  }
  // If user is not authenticated . . .
  if (!auth.isAuthenticated) {
    // Tell the user he needs to login to reach the page (e.g. if he clicked a link in an email)
    if (props.userSession.status === 'INITIAL') {
      addAlert('To proceed to the requested page, please log in', 'warning');
    }
    // . . . redirect to the login page
    return (
        <Route
            {...rest}
            render={props =>
                <Redirect to={{
                  pathname: '/login',
                  state: {referrer: props.location}
                }}
                />
            }
        />
    );
  }

  // if a permission is required to access the route and the user.permissions object is null, wait until the permissions are fetched from the server
  if (permission && !auth.user.permissions) {
    return null;
  }

  // If user is logged in but has no permission for the requested page, tell the user and redirect to the home page
  if (permission && auth.user.permissions.indexOf(permission) === -1) {
    addAlert('You do not have permission to access the page that you requested, and have been redirected to your \'Home\' page. If you require access, please contact your system administrator.', 'warning');
    return (
        <Route
            {...rest}
            render={props =>
                <Redirect to='/'/>
            }
        />
    );
  }

  // All is well; user is logged in and has permission. Go to the page.
  return (
      <Route
          {...rest}
          render={props =>
              <Component {...props} {...rest} />
          }
      />
  );
};

ProtectedRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  permission: PropTypes.string,
  viewOnly: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  userSession: state.userSession
});

const mapDispatchToProps = {
  addAlert: alertsOperations.addAlert,
  setSessionStatus: sessionOperations.setSessionStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
