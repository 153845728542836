import types from './types';

const setCurrentUser = (userData, preserveState) => ({
  type: types.SET_CURRENT_USER,
  payload: {userData, preserveState}
});

/**
 * Update the Sites in the auth.user object
 * @param sites (array) - list of sites
 * @returns {{type: string, payload: *}}
 */
const setUserSites = sites => ({
  type: types.GET_SITES_FOR_USER,
  payload: sites
});

/**
 * Update the Client in the auth.user object
 * @param client (object) - mongo client object
 * @returns {{type: string, payload: *}}
 */
const setClientForUser = client => ({
  type: types.GET_CLIENT_FOR_USER,
  payload: client
});

const setUserPermissions = permissions => ({
  type: types.SET_USER_PERMISSIONS,
  payload: permissions
});

const setTrueIdentity = identity => ({
  type: types.SET_TRUE_IDENTITY,
  payload: identity
});

const setImpersonatingUser = (impersonatingUserId, impersonatingDisplayName) => ({
  type: types.SET_IMPERSONATING_USER_ID,
  payload: {impersonatingUserId, impersonatingDisplayName}
});

const setImpersonateMode = user => ({
  type: types.SET_IMPERSONATE_MODE,
  payload: user
});

const setJumpList = jumpList => ({
  type: types.USER_SET_JUMP_LIST,
  payload: jumpList
});

export default {
  setCurrentUser,
  setUserSites,
  setClientForUser,
  setJumpList,
  setTrueIdentity,
  setUserPermissions,
  setImpersonatingUser,
  setImpersonateMode
};