import axios from 'axios';
import moment from 'moment';

//helper function to get the next task so that all the steps are using the same ordering method
const getNextTask = (formKey, user, webUpload = false, currentTaskId = null, externalUpload = false, isDocumentRejection = false, filterToTag = '', cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/internalDQ?formKey=${formKey}&webUpload=${webUpload}&currentTaskId=${currentTaskId}&externalUpload=${externalUpload}&isDocumentRejection=${isDocumentRejection}&filterToTag=${filterToTag}`, {cancelToken})
    .then(response =>
        resolve(response.data)
    )
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    });
  });
};

const claimItem = (taskId, email, completeTask, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .patch(`/v1/processes/internalReviewProcess/tasks/${taskId}`, {
      assignee: email,
      assignVariable: 'assignee',
      completeTask
    }, {cancelToken})
    .then(() => resolve())
    .catch(err => reject(err));
  });
};

const unclaimItem = (taskId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .patch(`/v1/processes/internalReviewProcess/tasks/${taskId}`, {
      assignee: null,
      assignVariable: 'assignee'
    }, {cancelToken})
    .then(() => resolve())
    .catch(err => reject(err));
  });
};

const _setTaskVariables = (taskId, variables, dbVariables, userId, activityOrActivities, reference, completeTask, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!taskId) {
      return reject(new Error("taskId is required"));
    }
    axios
    .patch(`/v1/processes/internalReviewProcess/tasks/${taskId}`, {
      variables,
      dbVariables,
      userId,
      activityOrActivities,
      reference,
      completeTask
    }, {cancelToken})
    .then(resolve)
    .catch(reject);
  })
};

const setTaskVariables = (taskId, variables, dbVariables, userId, activityOrActivities, reference, cancelToken = null) => {
  return _setTaskVariables(taskId, variables, dbVariables, userId, activityOrActivities, reference, false, cancelToken);
};

const setTaskVariablesAndComplete = (taskId, variables, dbVariables, userId, activityOrActivities, reference, cancelToken = null) => {
  return _setTaskVariables(taskId, variables, dbVariables, userId, activityOrActivities, reference, true, cancelToken);
};

const itemReassign = (taskId, assignee, assignVariable, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .patch(`/v1/processes/internalReviewProcess/tasks/${taskId}`, {
      assignee,
      assignVariable,
      reassign: true
    }, {cancelToken})
    .then(() => {
      axios
      .post('/v1/processes/internalReviewProcess/emitTaskList')
      .then(() => {
        resolve('success');
      })
      .catch(err => reject(err));
    })
    .catch(err => reject(err));
  });
};

const getDQItemActivity = (startDate, endDate, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/internalDQ?startDate=${startDate.format()}&endDate=${endDate.format()}`, {cancelToken})
    .then(response => resolve(response.data))
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    });
  });
};

const getAdditionalDQItemDataFromIds = (taskList, includeEmployeeInfo = true, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    // Just in case, make sure an empty task list was not passed in!
    if (!taskList || taskList.length === 0) {
      return resolve([]);
    }
    axios
    .get(`/v1/internalDQ?ids=${taskList}&includeEmployeeInfo=${includeEmployeeInfo}`, {cancelToken})
    .then(response => resolve(response.data))
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    });
  })
};

const completeDQItemForNewEmployeeFile = (taskId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .patch(`/v1/processes/internalReviewProcess/tasks/${taskId}`, {
      completeTask: true
    }, {cancelToken})
    .then(() => resolve())
    .catch(err => reject(err));
  });
};

const sendToCME = (dqItemId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .post(`/v1/internalDQ/${dqItemId}/sendToCME`, {}, {cancelToken})
    .then(() => resolve())
    .catch(err => reject(err));
  });
};

const getDQItemFromProcessInstanceId = (processInstanceId, fields, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/processes/internalReviewProcess/instances/${processInstanceId}?fields=${fields}`, {cancelToken})
    .then(response => resolve(response.data))
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    });
  });
};

const getImageAttachment = (dqItemId, documentId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/internalDQ/${dqItemId}/image/${documentId}`, {cancelToken})
    .then(response => resolve(response.data))
    .catch(reject);
  });
};

const downloadAttachment = (dqItemId, documentId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/internalDQ/${dqItemId}/download/${documentId}`, {responseType: 'blob', cancelToken})
    .then(response => resolve(response.data))
    .catch(reject);
  });
};

const splitPDFAttachment = (dqItemId, documentId, splitSpecs, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .post(`/v1/internalDQ/${dqItemId}/split/${documentId}`, splitSpecs, {cancelToken})
    .then(response => resolve(response.data))
    .catch(reject);
  })
};

const modifyDQItem = (dqItemId, dqItemChanges, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    if (!dqItemId) {
      return reject(new Error("DQ Item id is required"));
    }
    if (!dqItemChanges || Object.keys(dqItemChanges).length === 0) {
      return reject(new Error("Patch body is required"));
    }
    axios
    .patch(`/v1/internalDQ/${dqItemId}`, dqItemChanges, {cancelToken})
    .then(res => {
      if (res.status !== 204) {
        return reject(new Error("Unexpected response from server updating DQ Item"));
      }
      resolve(res.data);
    })
    .catch(err => {
      console.error(err);
      reject(err);
    });
  });
};

const reSendEmail = (dqItemId, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .post(`/v1/internalDQ/${dqItemId}/sendEmail`, {cancelToken})
    .then(response => {
      resolve();
    })
    .catch(err => {
      console.log(err);
      reject(err)
    });
  })
};

const sendErrorEmail = (sparcSection, info, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .post(`/v1/internalDQ/reportError/${sparcSection}`, info, {cancelToken})
    .then(response => {
      return resolve();
    })
    .catch(err => {
      console.error('Failed to send error message', err);
      reject(err);
    });
  });
};

const uploadDocument = (dqItemId, file, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('document', file);
    axios
    .post(`/v1/internalDQ/${dqItemId}/documents`, data, {cancelToken})
    .then(response => resolve(response.data))
    .catch(reject);
  });
};

/**
 * Create property for each Camunda Variable on the root / parent object.
 * After all variables are mapped to the root task object the variables collection is deleted
 */
const mapCamundaVariables = (taskList) => {
  for (const [, camTask] of Object.entries(taskList)) {
    if (camTask.variables) {
      camTask.variables.forEach(v => {
        let variableValue = v.value;
        if (v.type === 'Date' && v.value) {
          variableValue = moment(v.value);
        }
        if (v.name === 'received') {
          camTask.daysInQueue = moment(Date.now()).diff(moment(v.value), 'days');
        }
        camTask[v.name] = variableValue;
      });
      delete camTask.variables;
    }
  }
  return Object.keys(taskList).map(key => taskList[key]);
};

/**
 * instead of getting the enitre tasklist, just get what we need for external docs and cover sheet.
 *  we still need the camunda map variables to put it into the correct form
 * @param formKey
 * @param cancelToken
 * @returns {Promise<any>}
 */
const getFilteredTaskList = (formKey, cancelToken = null) => {
  return new Promise((resolve, reject) => {
    axios
    .get(`/v1/processes/internalReviewProcess/tasks?formKey=${formKey}`, {cancelToken})
    .then(response => resolve(mapCamundaVariables(response.data)))
    .catch(err => {
      if (axios.isCancel(err)) {
        return reject(err.message);
      }
      reject(err);
    });
  });
};

export default {
  claimItem,
  getNextTask,
  setTaskVariables,
  setTaskVariablesAndComplete,
  itemReassign,
  getDQItemActivity,
  getAdditionalDQItemDataFromIds,
  completeDQItemForNewEmployeeFile,
  getDQItemFromProcessInstanceId,
  sendToCME,
  getImageAttachment,
  downloadAttachment,
  splitPDFAttachment,
  modifyDQItem,
  reSendEmail,
  sendErrorEmail,
  uploadDocument,
  getFilteredTaskList,
  unclaimItem
};